
import {
  Event, Link, Info, Image, Duty, Notice, XDoc, EnumEventType, EnumEventCategory, RefEvent,
  Trip, Container, Place, Stay, Resto, Transfer
} from "src/dto.generated/api";
import { ItemObject } from "../models/item-object";
import { getContainerInTrip, getContainerParentContainerForTrip, getContainerParentPlaceForTrip } from "./utils-container";
import { isDutyInEvent } from "./utils-duty";
import { isImageInEvent } from "./utils-image";
import { isInfoInEvent } from "./utils-info";
import { isLinkInEvent } from "./utils-link";
import { isNoticeInEvent } from "./utils-notice";
import { getPlaceInTrip, getPlaceParentContainerForTrip, getPlaceParentPlaceForTrip } from "./utils-place";
import { getTransferInTrip, getTransferParentContainerForTrip, getTransferParentPlaceForTrip } from "./utils-transfer";



export function createNewEvent() {
  const newEvent: Event = <Event>{};
  newEvent.order = 1;
  newEvent.from = new Date(Date.UTC(0, 0, 1));
  newEvent.to = new Date(Date.UTC(0, 0, 1));
  newEvent.dateActive = 1;
  newEvent.links = new Array<Link>();
  newEvent.infos = new Array<Info>();
  newEvent.images = new Array<Image>();
  newEvent.duties = new Array<Duty>();
  newEvent.notices = new Array<Notice>();
  // newPlace.xDoc = new Array<XDoc>();
  return newEvent;
}

export function cloneEvent(event: Event) {
  const newEvent: Event = <Event>{};
  newEvent.id = event.id;
  newEvent.name = event.name;
  newEvent.order = event.order;
  newEvent.from = event.from;
  newEvent.to = event.to;
  newEvent.dateActive = event.dateActive;
  newEvent.zoom = event.zoom;
  newEvent.coordLon = event.coordLon;
  newEvent.coordLat = event.coordLat;
  newEvent.containerId = event.containerId;
  newEvent.placeId = event.placeId;
  newEvent.tripId = event.tripId;
  newEvent.transferId = event.transferId;
  newEvent.links = new Array<Link>();
  newEvent.infos = new Array<Info>();
  newEvent.images = new Array<Image>();
  newEvent.duties = new Array<Duty>();
  newEvent.notices = new Array<Notice>();
  return newEvent;
}

export function createNewEventFromRefEvent(refEvent: RefEvent) {
  // console.log("Util-container:createNewEventFromRefEvent", refEvent);
  const newEvent = createNewEvent();
  newEvent.refEventId = refEvent.id;
  newEvent.name = refEvent.name;
  if (refEvent.eventType !== undefined) { newEvent.eventType = refEvent.eventType; }
  if (refEvent.eventCategory !== undefined) { newEvent.eventCategory = refEvent.eventCategory; }
  if (refEvent.rating !== undefined) { newEvent.rating = refEvent.rating; }
  if (refEvent.description !== undefined) { newEvent.description = refEvent.description; }
  if (refEvent.descUrl !== undefined) { newEvent.descUrl = refEvent.descUrl; }
  if (refEvent.coordLon !== undefined) { newEvent.coordLon = refEvent.coordLon; }
  if (refEvent.coordLat !== undefined) { newEvent.coordLat = refEvent.coordLat; }
  if (refEvent.zoom !== undefined) { newEvent.zoom = refEvent.zoom; }
  newEvent.refEventId = refEvent.id;
  return newEvent;
}

// export function getNewEventId(events: Array<Event>) {
//   let id = 0;
//   for (const xEvent of events) {
//     if (xEvent.id < id) { id = xEvent.id; }
//   }
//   id--;
//   return id;
// }

export function convertEventDates(event: Event) {
  event.from = new Date(event.from);
  event.to = new Date(event.to);
}


export function isItemInEvent(item: ItemObject, event: Event) {
  if (item.itemType === "Duty") {
    if (isDutyInEvent(item.item, event)) { return true; }
  }
  if (item.itemType === "Info") {
    if (isInfoInEvent(item.item, event)) { return true; }
  }
  if (item.itemType === "Link") {
    if (isLinkInEvent(item.item, event)) { return true; }
  }
  if (item.itemType === "Image") {
    if (isImageInEvent(item.item, event)) { return true; }
  }
  if (item.itemType === "Notice") {
    if (isNoticeInEvent(item.item, event)) { return true; }
  }
}
export function isEventInContainer(event: Event, inContainer: Container) {

  // test all events directly in container
  for (const xEvent of inContainer.events) {
    if (event.id === xEvent.id) {
      return true;
    }
  }
  // test all child-containers of container
  for (const xContainer of inContainer.containers) {
    if (isEventInContainer(event, xContainer)) { return true; }
  }
  // test all child-places of container
  for (const xPlace of inContainer.places) {
    if (isEventInPlace(event, xPlace)) { return true; }
  }
  return false;
}

export function isEventInPlace(event: Event, inPlace: Place) {

  // test all events directly in place
  for (const xEvent of inPlace.events) {
    if (event.id === xEvent.id) {
      return true;
    }
  }
  // test all child-containers of place
  for (const xContainer of inPlace.containers) {
    if (isEventInContainer(event, xContainer)) { return true; }
  }
  // test all child-places of place
  for (const xPlace of inPlace.places) {
    if (isEventInPlace(event, xPlace)) { return true; }
  }
  return false;
}
export function isEventInTransfer(event: Event, inransfer: Transfer) {

  // test all events directly in place
  for (const xEvent of inransfer.events) {
    if (event.id === xEvent.id) {
      return true;
    }
  }
}

export function isEventPrivate(xEvent: Event, inTrip: Trip) {
  for (const xContainer of inTrip.containers) {
    if (!xContainer.userId) { continue; }
    if (isEventInContainer(xEvent, xContainer)) { return true; }
  }
  return false;
}


export function getMinLocalEventIdForTrip(inTrip: Trip) {
  let id = 0;
  // test events of trip
  for (const xEvent of inTrip.events) {
    if (xEvent.id < id) { id = xEvent.id; }
  }
  // search in trip-containers
  for (const xContainer of inTrip.containers) {
    const eventId = getMinLocalEventIdInContainer(xContainer);
    if (eventId < id) { id = eventId; }
  }
  // search in trip-places
  for (const xPlace of inTrip.places) {
    const eventId = getMinLocalEventIdInPlace(xPlace);
    if (eventId < id) { id = eventId; }
  }
  // search in trip-transfers
  for (const xTransfer of inTrip.transfers) {
    const eventId = getMinLocalEventIdInTransfer(xTransfer);
    if (eventId < id) { id = eventId; }
  }
  return id;
}
export function getMinLocalEventIdInContainer(inContainer: Container) {
  // console.log("EventFind:getPlaceInContainer-placeId", placeId);
  let id = 0;
  // test events of container
  for (const xEvent of inContainer.events) {
    if (xEvent.id < id) { id = xEvent.id; }
  }
  // search in container-containers
  for (const xContainer of inContainer.containers) {
    const eventId = getMinLocalEventIdInContainer(xContainer);
    if (eventId < id) { id = eventId; }
  }
  // search in container-places
  for (const xPlace of inContainer.places) {
    const eventId = getMinLocalEventIdInPlace(xPlace);
    if (eventId < id) { id = eventId; }
  }
  // search in trip-transfers
  for (const xTransfer of inContainer.transfers) {
    const eventId = getMinLocalEventIdInTransfer(xTransfer);
    if (eventId < id) { id = eventId; }
  }
  return id;
}
export function getMinLocalEventIdInPlace(inPlace: Place) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test events of place
  for (const xEvent of inPlace.events) {
    if (xEvent.id < id) { id = xEvent.id; }
  }
  // search in place-containers
  for (const xContainer of inPlace.containers) {
    const eventId = getMinLocalEventIdInContainer(xContainer);
    if (eventId < id) { id = eventId; }
  }
  // search in place-places
  for (const xPlace of inPlace.places) {
    const eventId = getMinLocalEventIdInPlace(xPlace);
    if (eventId < id) { id = eventId; }
  }
  return id;
}
export function getMinLocalEventIdInTransfer(inTransfer: Transfer) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test events of transfer
  for (const xEvent of inTransfer.events) {
    if (xEvent.id < id) { id = xEvent.id; }
  }
  return id;
}


export function getEventParentContainerForTrip(event: Event, trip: Trip, onlyForRefRegion: boolean) {
  // console.log("EventFind:getEventParentContainerForTrip-event", event);

  // parent is trip
  if (event.tripId) { return null; }

  // parent is container
  if (event.containerId) {
    const parentContainer = getContainerInTrip(event.containerId, trip);
    // console.log("EventFind:getEventParentContainerForTrip-parentContainer", parentContainer);
    if (!onlyForRefRegion) { return parentContainer; }
    if (parentContainer.refRegionId || parentContainer.refContainerId) { return parentContainer; }
    const resContainer = getContainerParentContainerForTrip(parentContainer, trip, onlyForRefRegion);
    if (resContainer) { return resContainer; }
  }

  // parent is place
  if (event.placeId) {
    const parentPlace = getPlaceInTrip(event.placeId, trip);
    // console.log("EventFind:getEventParentContainerForTrip-parentPlace", parentPlace);
    const resContainer = getPlaceParentContainerForTrip(parentPlace, trip, onlyForRefRegion);
    if (resContainer) { return resContainer; }
  }

  // parent is transfer
  if (event.transferId) {
    const parentTransfer = getTransferInTrip(event.transferId, trip);
    // console.log("EventFind:getEventParentContainerForTrip-parentPlace", parentTransfer);
    const resContainer = getTransferParentContainerForTrip(parentTransfer, trip, onlyForRefRegion);
    if (resContainer) { return resContainer; }
  }
}

export function getEventParentPlaceForTrip(event: Event, trip: Trip, onlyForRefPlace: boolean) {
  // console.log("EventFind:getEventParentPlaceForTrip-event", event);

  // parent is container
  if (event.containerId) {
    const parentContainer = getContainerInTrip(event.containerId, trip);
    console.log("EventFind:getEventParentPlaceForTrip-parentContainer", parentContainer);
    const resPlace = getContainerParentPlaceForTrip(parentContainer, trip, onlyForRefPlace);
    if (resPlace) { return resPlace; }
  }

  // parent is place
  if (event.placeId) {
    const parentPlace = getPlaceInTrip(event.placeId, trip);
    console.log("EventFind:getEventParentPlaceForTrip-parentPlace", parentPlace);
    if (!onlyForRefPlace) { return parentPlace; }
    if (parentPlace.refPlaceId) { return parentPlace; }
    const resPlace = getPlaceParentPlaceForTrip(parentPlace, trip, onlyForRefPlace);
    if (resPlace) { return resPlace; }
  }
  // parent is transfer
  if (event.transferId) {
    const parentTransfer = getTransferInTrip(event.transferId, trip);
    console.log("EventFind:getEventParentPlaceForTrip-parentTransfer", parentTransfer);
    const resPlace = getTransferParentPlaceForTrip(parentTransfer, trip, onlyForRefPlace);
    if (resPlace) { return resPlace; }
  }
}

export function getEventInTrip(eventId: number, inTrip: Trip) {
  console.log("util-events:getEventInTrip-eventId", eventId);

  // search in events
  for (const xEvent of inTrip.events) {
    if (xEvent.id === eventId) { return xEvent; }
  }
  // search in containers
  for (const xContainer of inTrip.containers) {
    const xEvent = getEventInContainer(eventId, xContainer);
    if (xEvent) { return xEvent; }
  }
  // search in sub-places
  for (const xPlace of inTrip.places) {
    const xEvent = getEventInPlace(eventId, xPlace);
    if (xEvent) { return xEvent; }
  }
  // search in sub-transfers
  for (const xTransfer of inTrip.transfers) {
    const xEvent = getEventInTransfer(eventId, xTransfer);
    if (xEvent) { return xEvent; }
  }
}
export function getEventInContainer(eventId: number, inContainer: Container) {
  // console.log("util-events:getEventInContainer-eventId", eventId);

  // search in events
  for (const xEvent of inContainer.events) {
    if (xEvent.id === eventId) { return xEvent; }
  }

  // search in sub-containers
  for (const xContainer of inContainer.containers) {
    const xEvent = getEventInContainer(eventId, xContainer);
    if (xEvent) { return xEvent; }
  }
  // search in places
  for (const xPlace of inContainer.places) {
    const xEvent = getEventInPlace(eventId, xPlace);
    if (xEvent) { return xEvent; }
  }
  // search in transfers
  for (const xTransfer of inContainer.transfers) {
    const xEvent = getEventInTransfer(eventId, xTransfer);
    if (xEvent) { return xEvent; }
  }
}
export function getEventInPlace(eventId: number, inPlace: Place) {
  console.log("util-events:getEventInPlace-eventId", eventId);

  // search in events
  for (const xEvent of inPlace.events) {
    if (xEvent.id === eventId) { return xEvent; }
  }

  // search in sub-containers
  for (const xContainer of inPlace.containers) {
    const xEvent = getEventInContainer(eventId, xContainer);
    if (xEvent) { return xEvent; }
  }
  // search in places
  for (const xPlace of inPlace.places) {
    const xEvent = getEventInPlace(eventId, xPlace);
    if (xEvent) { return xEvent; }
  }
}

export function getEventInTransfer(eventId: number, inTransfer: Transfer) {
  // console.log("util-events:getEventInTransfer-eventId", eventId);

  // search in events
  for (const xEvent of inTransfer.events) {
    if (xEvent.id === eventId) { return xEvent; }
  }
}

