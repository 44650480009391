import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatToolbarModule } from "@angular/material/toolbar";


import {
  RockItPwaToolsFrontendModule,
  GoogleLoginProvider,
  RockitSocialLoginPopupComponent,
  RockItApiConfig,
  DevelopmentLoginProvider,
  SocialAuthServiceConfig,
  RockItAuthenticationService
} from "rockit-pwatools-frontend";


import { ClientSettingsService } from "./services/client-settings.service";
import { GlobalService } from "./services/global.service";
import { TripService } from "./services/trip.service";
import { RefItemService } from "./services/ref-item.service";
import { HttpClientModule } from "@angular/common/http";
import { RoutePlanningService } from "./services/route-planning.service";

// import { FileUploadModule } from "ng2-file-upload";
// import { ORIGIN_URL } from "@nguniversal/aspnetcore-engine";
// import { FileUploadControl } from "./controls/file-uploader/file-upload.control";


const authConfigProduction = {
  autoLogin: false,
  providers: [
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider("797644242871-29mhcpktku3vr4qv3mtt0t23fggcb1td.apps.googleusercontent.com")
    }
    // {
    //   id: FacebookLoginProvider.PROVIDER_ID,
    //   provider: new FacebookLoginProvider("????")
    // }
  ]
} as SocialAuthServiceConfig;

const authConfigDevelop = {
  autoLogin: false,
  providers: [
    {
      id: DevelopmentLoginProvider.PROVIDER_ID,
      provider: new DevelopmentLoginProvider()
    }
  ]
} as SocialAuthServiceConfig;

export function provideAuthConfig() {
  if (window.location.href.startsWith("http://localhost") ||
    window.location.href.startsWith("https://localhost")) {
    return authConfigDevelop;
  }

  return authConfigProduction;
}

const apiConfig = new RockItApiConfig(environment.apiEndpoint);
console.log("Environment is: ", apiConfig);

export function provideApiConfig() {
  return apiConfig;
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    // BackButtonDisableModule.forRoot({
    //   preserveScrollPosition: true
    // }),
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production }),
    RouterModule,
    BrowserAnimationsModule,
    HttpClientModule,

    MatToolbarModule,

    RockItPwaToolsFrontendModule,
  ],
  exports: [],
  providers: [
    {
      provide: "SocialAuthServiceConfig",
      useFactory: provideAuthConfig
    },
    {
      provide: RockItApiConfig,
      useFactory: provideApiConfig
    },
    ClientSettingsService,
    RockItAuthenticationService,
    GlobalService,
    TripService,
    RefItemService,
    RoutePlanningService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
