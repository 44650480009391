<div>

  <mat-toolbar class="app-header">

    <div style="display: flex; width:100%; max-width: 100%; flex-direction: row;">
      <div style="flex-grow: 0; flex-shrink: 1; flex-basis: auto; vertical-align: center;">
        <img width="30px" height="30px" src="./assets/icons/t4y-icon_80x80.gif" style="margin-bottom:8px;"
          alt="trip4you-icon">
        <span class="app-name" style="margin-left:0.5em;">Trip4You</span>
      </div>

      <div
        style="flex-grow: 1; flex-shrink: 0; flex-basis: content; font-size:large; text-align: center; vertical-align:center;">
        <!-- <img src="./assets/icons/web-cancel.svg" [class.hidden]="!isOnline" title="you are offline"> -->
        <span *ngIf="!isOnline" style="margin-left:0.5rem;">offline</span>
      </div>

      <div *ngIf="isOnline"
        style="flex-grow: 0; flex-shrink: 1; flex-basis: content; text-overflow: ellipsis; overflow: hidden; padding-right: 0.5em;"
        class="topbar-user-container">
        <rockit-social-login></rockit-social-login>
      </div>
    </div>

  </mat-toolbar>

</div>

<router-outlet></router-outlet>