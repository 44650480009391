
import { Container, Stay, Trip, XDoc } from "src/dto.generated/api";


export function createNewXDoc() {
  const newXDoc: XDoc = <XDoc>{};
  newXDoc.order = 1;
  return newXDoc;
}

export function isXDocInContainer(info: XDoc, container: Container) {

  // test all infos directly in container
  for (const xXDoc of container.xdocs) {
    if (info.id === xXDoc.id) {
      return true;
    }
  }
  // test all child-containers of container
  for (const xContainer of container.containers) {
    if (isXDocInContainer(info, xContainer)) { return true; }
  }
  // test all child-stays of container
  for (const xStay of container.stays) {
    if (isXDocInStay(info, xStay)) { return true; }
  }
  return false;
}

export function isXDocInStay(info: XDoc, stay: Stay) {

  // test all infos directly in stay
  for (const xXDoc of stay.xDocs) {
    if (info.id === xXDoc.id) {
      return true;
    }
  }
  return false;
}


export function isXDocPrivate(info: XDoc, inTrip: Trip) {
  for (const xContainer of inTrip.containers) {
    if (!xContainer.userId) { continue; }
    if (isXDocInContainer(info, xContainer)) { return true; }
  }
  return false;
}
