import { Injectable, Inject, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { RockItCommandSenderService } from "rockit-pwatools-frontend";
import { AddImageContentCommand, AddImageContentCommandResult, EnumActionType, EnumAppType } from "../../dto.generated/api";
import { RemoveImageContentCommand, RemoveImageContentCommandResult } from "../../dto.generated/api";
import { AddRefImageContentCommand, AddRefImageContentCommandResult } from "../../dto.generated/api";
import { RemoveRefImageContentCommand, RemoveRefImageContentCommandResult } from "../../dto.generated/api";
import { AddXDocContentCommand, AddXDocContentCommandResult } from "../../dto.generated/api";
import { RemoveXDocContentCommand, RemoveXDocContentCommandResult } from "../../dto.generated/api";
import { AddUsageLogCommand, AddUsageLogCommandResult } from "../../dto.generated/api";
import { GetUsageNrOfUsersCommand, GetUsageNrOfUsersCommandResult } from "../../dto.generated/api";
import { GetUsageNrOfTotalCommand, GetUsageNrOfTotalCommandResult } from "../../dto.generated/api";
import { GetUsageOfUserCommand, GetUsageOfUserCommandResult } from "../../dto.generated/api";
import { GetUsageNrOfUserIPsCommand, GetUsageNrOfUserIPsCommandResult } from "../../dto.generated/api";
import { ClientSettingsService } from "./client-settings.service";
import { UsageLog, User } from "../../dto.generated/api";


@Injectable()
export class GlobalService {

  public mediaWidth = 0;
  public mediaHeight = 0;
  private editMode = false;
  private orderAvailable = false;
  private loggedInUser: User;

  constructor(
    private commandSenderService: RockItCommandSenderService,
    private clientSettingsService: ClientSettingsService,
    private http: HttpClient) { }

  // mediaWidth & height
  public setMediaWidth(width: number) {
    this.mediaWidth = width;
  }
  public getMediaWidth() {
    return this.mediaWidth;
  }
  public setMediaHeight(height: number) {
    this.mediaHeight = height;
  }
  public getMediaHeight() {
    return this.mediaHeight;
  }

  // editMode
  public setEditMode(editMode: boolean) {
    this.editMode = editMode;
  }
  public getEditMode() {
    return this.editMode;
  }

  // orderFunctions
  public setOrderAvailable(orderFunctions: boolean) {
    this.orderAvailable = orderFunctions;
  }
  public getOrderAvailable() {
    return this.orderAvailable;
  }

  public setLoggedInUser(user: User) {
    this.loggedInUser = user;
  }
  public getLoggedInUser() {
    return this.loggedInUser;
  }

  public isLogOn() {
    return true;
  }

  public async sendImageToServer(base64Image: string) {
    console.log("sendImageToServer-base64Image:");
    const result = await this.commandSenderService.sendCommand<AddImageContentCommandResult>
      (AddImageContentCommand.create({ base64Image: base64Image }));
    // console.log("sendImageToServer-result:", result);
    return result;
  }
  public async removeImageFromServer(blobString: string) {
    console.log("removeImageFromServer:");
    const result = await this.commandSenderService.sendCommand<RemoveImageContentCommandResult>
      (RemoveImageContentCommand.create({ blobString: blobString }));
    // console.log("removeImageFromServer-result:", result);
    return result;
  }
  public getImageBaseUrl() {
    return `${this.clientSettingsService.storageConnectionString}travelplannerimages/`;
  }

  public async sendRefImageToServer(base64Image: string) {
    console.log("sendRefImageToServer-base64Image:");
    const result = await this.commandSenderService.sendCommand<AddRefImageContentCommandResult>
      (AddRefImageContentCommand.create({ base64Image: base64Image }));
    // console.log("sendRefImageToServer-result:", result);
    return result;
  }
  public async removeRefImageFromServer(blobString: string) {
    console.log("removeRefImageFromServer:");
    const result = await this.commandSenderService.sendCommand<RemoveRefImageContentCommandResult>
      (RemoveRefImageContentCommand.create({ blobString: blobString }));
    // console.log("removeRefImageFromServer-result:", result);
    return result;
  }
  public getRefImageBaseUrl() {
    return `${this.clientSettingsService.storageConnectionString}travelplannerrefimages/`;
  }


  public async sendXDocToServer(base64Doc: string, fileExtension: string) {
    console.log("sendXDocToServer-base64Doc:");
    const result = await this.commandSenderService.sendCommand<AddXDocContentCommandResult>
      (AddXDocContentCommand.create({ base64Doc: base64Doc, fileExtension: fileExtension }));
    console.log("sendXDocToServer-result:", result);
    return result;
  }
  public async removeXDocFromServer(blobString: string) {
    console.log("removeXDocFromServer:");
    const result = await this.commandSenderService.sendCommand<RemoveXDocContentCommandResult>
      (RemoveXDocContentCommand.create({ blobString: blobString }));
    // console.log("removeRefImageFromServer-result:", result);
    return result;
  }
  public getXDocBaseUrl() {
    return `${this.clientSettingsService.storageConnectionString}travelplannerdocs/`;
  }

  public async addUsageLog(usageLog: UsageLog) {
    const result = await this.commandSenderService.sendCommand<AddUsageLogCommandResult>
      (AddUsageLogCommand.create({ newUsageLog: usageLog }));
    return result.addedUsageLog;
  }

  public async getUsageNrOfUserIPs(appType: EnumAppType, actionType: EnumActionType, fromDate: Date, toDate: Date) {
    // console.log("getUsageNrOfUserIPs-actionType:", actionType);
    const result = await this.commandSenderService.sendCommand<GetUsageNrOfUserIPsCommandResult>
      (GetUsageNrOfUserIPsCommand.create({ appType: appType, actionType: actionType, fromDate: fromDate, toDate: toDate }));
    // console.log("getUsageNrOfUserIPs-result:", result);
    return result.nrOfUserIPs;
  }

  public async getUsageNrOfUsers(appType: EnumAppType, actionType: EnumActionType, fromDate: Date, toDate: Date) {
    // console.log("getUsageNrOfUsers-actionType:", actionType);
    const result = await this.commandSenderService.sendCommand<GetUsageNrOfUsersCommandResult>
      (GetUsageNrOfUsersCommand.create({ appType: appType, actionType: actionType, fromDate: fromDate, toDate: toDate }));
    // console.log("getUsageNrOfUsers-result:", result);
    return result.nrOfUsers;
  }
  public async getUsageNrOfTotal(appType: EnumAppType, actionType: EnumActionType, fromDate: Date, toDate: Date) {
    // console.log("getUsageNrOfTotal-actionType:", actionType);
    const result = await this.commandSenderService.sendCommand<GetUsageNrOfTotalCommandResult>
      (GetUsageNrOfTotalCommand.create({ appType: appType, actionType: actionType, fromDate: fromDate, toDate: toDate }));
    // console.log("getUsageNrOfTotal-result:", result);
    return result.nrOfTotal;
  }
  public async getUsageOfUser(userId: number, appType: EnumAppType, actionType: EnumActionType, fromDate: Date, toDate: Date) {
    console.log("getUsageOfUser-actionType:", actionType);
    const result = await this.commandSenderService.sendCommand<GetUsageOfUserCommandResult>
      (GetUsageOfUserCommand.create({ userId: userId, appType: appType, actionType: actionType, fromDate: fromDate, toDate: toDate }));
    console.log("getUsageOfUser-result:", result);
    return result.nrOfUses;
  }
}
