
import { Link, RefLink, EnumThemeType, Trip, Container, Place, Event, Stay, Resto, Transfer } from "src/dto.generated/api";

export function createNewLink() {
  const newLink: Link = <Link>{};
  newLink.linkType = EnumThemeType.Undef;
  newLink.order = 1;
  return newLink;
}

export function cloneLink(link: Link) {
  const newLink: Link = <Link>{};
  newLink.id = link.id;
  newLink.order = link.order;
  newLink.title = link.title;
  newLink.providerName = link.providerName;
  newLink.description = link.description;
  newLink.url = link.url;
  newLink.linkType = link.linkType;
  newLink.containerId = link.containerId;
  newLink.eventId = link.eventId;
  newLink.placeId = link.placeId;
  newLink.restoId = link.restoId;
  newLink.stayId = link.stayId;
  newLink.transferId = link.transferId;
  newLink.tripId = link.tripId;
  return newLink;
}

export function createNewLinkFromRefLink(refLink: RefLink) {
  const newLink = createNewLink();
  newLink.refLinkId = refLink.id;
  newLink.title = refLink.title;
  newLink.providerName = refLink.providerName;
  newLink.url = refLink.url;
  return newLink;
}

// export function getNewLinkId(links: Array<Link>) {
//   let id = 0;
//   for (const xLink of links) {
//     if (xLink.id < id) { id = xLink.id; }
//   }
//   id--;
//   return id;
// }


export function isLinkInContainer(link: Link, container: Container) {

  // test all link directly in container
  for (const xLink of container.links) {
    if (link.id === xLink.id) {
      return true;
    }
  }
  // test all child-containers of container
  for (const xContainer of container.containers) {
    if (isLinkInContainer(link, xContainer)) { return true; }
  }
  // test all child-places of container
  for (const xPlace of container.places) {
    if (isLinkInPlace(link, xPlace)) { return true; }
  }
  // test all child-events of place
  for (const xEvent of container.events) {
    if (isLinkInEvent(link, xEvent)) { return true; }
  }
  // test all child-stays of place
  for (const xStay of container.stays) {
    if (isLinkInStay(link, xStay)) { return true; }
  }
  // test all child-restos of place
  for (const xResto of container.restos) {
    if (isLinkInResto(link, xResto)) { return true; }
  }
  return false;
}

export function isLinkInPlace(link: Link, place: Place) {

  // test all links directly in place
  for (const xLink of place.links) {
    if (link.id === xLink.id) {
      return true;
    }
  }
  // test all child-containers of place
  for (const xContainer of place.containers) {
    if (isLinkInContainer(link, xContainer)) { return true; }
  }
  // test all child-places of place
  for (const xPlace of place.places) {
    if (isLinkInPlace(link, xPlace)) { return true; }
  }
  // test all child-events of place
  for (const xEvent of place.events) {
    if (isLinkInEvent(link, xEvent)) { return true; }
  }
  // test all child-stays of place
  for (const xStay of place.stays) {
    if (isLinkInStay(link, xStay)) { return true; }
  }
  // test all child-restos of place
  for (const xResto of place.restos) {
    if (isLinkInResto(link, xResto)) { return true; }
  }
  return false;
}

export function isLinkInEvent(link: Link, event: Event) {

  // test all links directly in event
  for (const xLink of event.links) {
    if (link.id === xLink.id) {
      return true;
    }
  }
  return false;
}

export function isLinkInStay(link: Link, stay: Stay) {

  // test all links directly in stay
  for (const xLink of stay.links) {
    if (link.id === xLink.id) {
      return true;
    }
  }
  return false;
}

export function isLinkInResto(link: Link, resto: Resto) {

  // test all links directly in resto
  for (const xLink of resto.links) {
    if (link.id === xLink.id) {
      return true;
    }
  }
  return false;
}

export function isLinkInTransfer(link: Link, transfer: Transfer) {

  // test all links directly in transfer
  for (const xLink of transfer.links) {
    if (link.id === xLink.id) {
      return true;
    }
  }
  return false;
}

export function isLinkPrivate(link: Link, inTrip: Trip) {
  for (const xContainer of inTrip.containers) {
    if (!xContainer.userId) { continue; }
    if (isLinkInContainer(link, xContainer)) { return true; }
  }
  return false;
}

export function getMinLocalLinkIdForTrip(inTrip: Trip) {
  let id = 0;
  // test Links of trip
  for (const xLink of inTrip.links) {
    if (xLink.id < id) { id = xLink.id; }
  }
  // search in trip-containers
  for (const xContainer of inTrip.containers) {
    const linkId = getMinLocalLinkIdInContainer(xContainer);
    if (linkId < id) { id = linkId; }
  }
  // search in trip-events
  for (const xEvent of inTrip.events) {
    const linkId = getMinLocalLinkIdInEvent(xEvent);
    if (linkId < id) { id = linkId; }
  }
  // search in trip-places
  for (const xPlace of inTrip.places) {
    const linkId = getMinLocalLinkIdInPlace(xPlace);
    if (linkId < id) { id = linkId; }
  }
  // search in trip-restos
  for (const xResto of inTrip.restos) {
    const elinkId = getMinLocalLinkIdInResto(xResto);
    if (elinkId < id) { id = elinkId; }
  }
  // search in trip-stays
  for (const xStay of inTrip.stays) {
    const linkId = getMinLocalLinkIdInStay(xStay);
    if (linkId < id) { id = linkId; }
  }
  // search in trip-transfers
  for (const xTransfer of inTrip.transfers) {
    const linkId = getMinLocalLinkIdInTransfer(xTransfer);
    if (linkId < id) { id = linkId; }
  }
  return id;
}
export function getMinLocalLinkIdInContainer(inContainer: Container) {
  // console.log("EventFind:getPlaceInContainer-placeId", placeId);
  let id = 0;
  // test links of container
  for (const xLink of inContainer.links) {
    if (xLink.id < id) { id = xLink.id; }
  }
  // search in container-containers
  for (const xContainer of inContainer.containers) {
    const elinkId = getMinLocalLinkIdInContainer(xContainer);
    if (elinkId < id) { id = elinkId; }
  }
  // search in container-events
  for (const xEvent of inContainer.events) {
    const linkId = getMinLocalLinkIdInEvent(xEvent);
    if (linkId < id) { id = linkId; }
  }
  // search in container-places
  for (const xPlace of inContainer.places) {
    const linkId = getMinLocalLinkIdInPlace(xPlace);
    if (linkId < id) { id = linkId; }
  }
  // search in container-restos
  for (const xResto of inContainer.restos) {
    const linkId = getMinLocalLinkIdInResto(xResto);
    if (linkId < id) { id = linkId; }
  }
  // search in container-stays
  for (const xStay of inContainer.stays) {
    const linkId = getMinLocalLinkIdInStay(xStay);
    if (linkId < id) { id = linkId; }
  }
  // search in trip-transfers
  for (const xTransfer of inContainer.transfers) {
    const linkId = getMinLocalLinkIdInTransfer(xTransfer);
    if (linkId < id) { id = linkId; }
  }
  return id;
}
export function getMinLocalLinkIdInEvent(inEvent: Event) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test links of event
  for (const xLink of inEvent.links) {
    if (xLink.id < id) { id = xLink.id; }
  }
  return id;
}
export function getMinLocalLinkIdInPlace(inPlace: Place) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test links of place
  for (const xLink of inPlace.links) {
    if (xLink.id < id) { id = xLink.id; }
  }
  // search in place-containers
  for (const xContainer of inPlace.containers) {
    const linkId = getMinLocalLinkIdInContainer(xContainer);
    if (linkId < id) { id = linkId; }
  }
  // search in place-events
  for (const xEvent of inPlace.events) {
    const linkId = getMinLocalLinkIdInEvent(xEvent);
    if (linkId < id) { id = linkId; }
  }
  // search in place-places
  for (const xPlace of inPlace.places) {
    const linkId = getMinLocalLinkIdInPlace(xPlace);
    if (linkId < id) { id = linkId; }
  }
  // search in place-restos
  for (const xResto of inPlace.restos) {
    const linkId = getMinLocalLinkIdInResto(xResto);
    if (linkId < id) { id = linkId; }
  }
  // search in place-stays
  for (const xStay of inPlace.stays) {
    const linkId = getMinLocalLinkIdInStay(xStay);
    if (linkId < id) { id = linkId; }
  }
  return id;
}
export function getMinLocalLinkIdInResto(inResto: Resto) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test links of event
  for (const xLink of inResto.links) {
    if (xLink.id < id) { id = xLink.id; }
  }
  return id;
}
export function getMinLocalLinkIdInStay(inStay: Stay) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test links of event
  for (const xLink of inStay.links) {
    if (xLink.id < id) { id = xLink.id; }
  }
  return id;
}
export function getMinLocalLinkIdInTransfer(inTransfer: Transfer) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test links of transfer
  for (const xLink of inTransfer.links) {
    if (xLink.id < id) { id = xLink.id; }
  }
  return id;
}

