import { Injectable } from "@angular/core";
import { Track } from "../models/track";

@Injectable()
export class RoutePlanningService {
  private currentRouteX: any;
  private currentTrackX: Track;

  public constructor() {
  }

  public setCurrentRoute(route: any) {
    this.currentRouteX = route;
  }

  public getCurrentRoute(): any {
    return this.currentRouteX;
  }

  public setCurrentTrack(track: Track) {
    this.currentTrackX = track;
  }
  public getCurrentTrack(): Track {
    return this.currentTrackX;
  }
}
