import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { RockItCommandSenderService } from "rockit-pwatools-frontend";
import { fromEvent, Observable, Subscription } from "rxjs";
import { environment } from "src/environments/environment";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {

  public releaseDate: string = environment.releaseDate;
  public isOnline: boolean;

  private offlineEvent: Observable<Event>;
  private offLineSubscription: Subscription;
  private onlineEvent: Observable<Event>;
  private onLineSubscription: Subscription;

  public ngOnInit() {
    this.isOnline = navigator.onLine;
    console.log("App:ngOnInit-isOnline", this.isOnline);

    this.offlineEvent = fromEvent(window, "offline");
    this.offLineSubscription = this.offlineEvent.subscribe(e => {
      console.log("App:offlineEvent");
      this.isOnline = false;
    });

    this.onlineEvent = fromEvent(window, "online");
    this.onLineSubscription = this.onlineEvent.subscribe(e => {
      console.log("App:onlineEvent");
      this.isOnline = true;
    });
  }
  public ngOnDestroy() {
    this.offLineSubscription.unsubscribe();
    this.onLineSubscription.unsubscribe();
  }

}
