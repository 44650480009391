import { Injectable, Inject, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import {
  RefRegion, RefContainer, RefPlace, RefTransfer, RefEvent, RefStay, RefResto, RefOLI, User,
  EnumImageSelection, EnumObjectType,
} from "../../dto.generated/api";
import { RefLink, RefInfo, RefImage } from "../../dto.generated/api";
import { RockItCommandSenderService } from "rockit-pwatools-frontend";
import { DoImportGeonamesCountriesCommandResult, DoImportGeonamesCountriesCommand } from "../../dto.generated/api";
import { DoImportGeonamesPOICommand, DoImportGeonamesPOICommandResult } from "../../dto.generated/api";
import { GetAllRefRegionsCommand, GetAllRefRegionsCommandResult } from "../../dto.generated/api";
import { GetAllRefRegionsPrepairedCommandResult, GetAllRefRegionsPrepairedCommand } from "../../dto.generated/api";
import { GetRefRegionCommand, GetRefRegionCommandResult } from "../../dto.generated/api";
import { GetRefRegionContainersCommand, GetRefRegionContainersCommandResult } from "../../dto.generated/api";
import { GetRefRegionEventsCommand, GetRefRegionEventsCommandResult } from "../../dto.generated/api";
import { GetRefRegionImagesCommand, GetRefRegionImagesCommandResult } from "../../dto.generated/api";
import { GetRefRegionInfosCommand, GetRefRegionInfosCommandResult } from "../../dto.generated/api";
import { GetRefRegionLinksCommand, GetRefRegionLinksCommandResult } from "../../dto.generated/api";
import { GetRefRegionOlisCommand, GetRefRegionOlisCommandResult } from "../../dto.generated/api";
import { GetRefRegionPlacesCommand, GetRefRegionPlacesCommandResult } from "../../dto.generated/api";
import { GetRefSubRegionsCommand, GetRefSubRegionsCommandResult } from "../../dto.generated/api";
import { SearchRefRegionByNameCommand, SearchRefRegionByNameCommandResult } from "../../dto.generated/api";
import { SearchRefRegionListByNameCommand, SearchRefRegionListByNameCommandResult } from "../../dto.generated/api";
import { AddRefOLICommand, AddRefOLICommandResult } from "../../dto.generated/api";
import { UpdateRefOLICommand, UpdateRefOLICommandResult } from "../../dto.generated/api";
import { DeleteRefOLICommand, DeleteRefOLICommandResult } from "../../dto.generated/api";
import { AddRefRegionCommand, AddRefRegionCommandResult } from "../../dto.generated/api";
import { UpdateRefRegionCommand, UpdateRefRegionCommandResult } from "../../dto.generated/api";
import { DeleteRefRegionCommand, DeleteRefRegionCommandResult } from "../../dto.generated/api";
import { AddRefContainerCommand, AddRefContainerCommandResult } from "../../dto.generated/api";
import { UpdateRefContainerCommand, UpdateRefContainerCommandResult } from "../../dto.generated/api";
import { DeleteRefContainerCommand, DeleteRefContainerCommandResult } from "../../dto.generated/api";
import { GetRefContainerCommand, GetRefContainerCommandResult } from "../../dto.generated/api";
import { GetRefContainerImagesCommand, GetRefContainerImagesCommandResult } from "../../dto.generated/api";
import { AddRefPlaceCommand, AddRefPlaceCommandResult } from "../../dto.generated/api";
import { UpdateRefPlaceCommand, UpdateRefPlaceCommandResult } from "../../dto.generated/api";
import { DeleteRefPlaceCommand, DeleteRefPlaceCommandResult } from "../../dto.generated/api";
import { GetRefPlaceCommand, GetRefPlaceCommandResult } from "../../dto.generated/api";
import { GetRefPlaceImagesCommand, GetRefPlaceImagesCommandResult } from "../../dto.generated/api";
import { SearchRefPlaceByNameCommand, SearchRefPlaceByNameCommandResult } from "../../dto.generated/api";
import { SearchRefPlaceListByNameCommand, SearchRefPlaceListByNameCommandResult } from "../../dto.generated/api";
import { AddRefTransferCommand, AddRefTransferCommandResult } from "../../dto.generated/api";
import { GetRefTransfersCommand, GetRefTransfersCommandResult } from "../../dto.generated/api";
import { GetRefTransfersForRegionCommand, GetRefTransfersForRegionCommandResult } from "../../dto.generated/api";
import { UpdateRefTransferCommand, UpdateRefTransferCommandResult } from "../../dto.generated/api";
import { DeleteRefTransferCommand, DeleteRefTransferCommandResult } from "../../dto.generated/api";
import { AddRefLinkCommand, AddRefLinkCommandResult } from "../../dto.generated/api";
import { UpdateRefLinkCommand, UpdateRefLinkCommandResult } from "../../dto.generated/api";
import { GetRefLinkCommand, GetRefLinkCommandResult } from "../../dto.generated/api";
import { DeleteRefLinkCommand, DeleteRefLinkCommandResult } from "../../dto.generated/api";
import { AddRefInfoCommand, AddRefInfoCommandResult } from "../../dto.generated/api";
import { GetRefInfoCommand, GetRefInfoCommandResult } from "../../dto.generated/api";
import { UpdateRefInfoCommand, UpdateRefInfoCommandResult } from "../../dto.generated/api";
import { DeleteRefInfoCommand, DeleteRefInfoCommandResult } from "../../dto.generated/api";
import { AddRefImageCommand, AddRefImageCommandResult } from "../../dto.generated/api";
import { UpdateRefImageCommand, UpdateRefImageCommandResult } from "../../dto.generated/api";
import { DeleteRefImageCommand, DeleteRefImageCommandResult } from "../../dto.generated/api";
import { GetRefImageCommand, GetRefImageCommandResult } from "../../dto.generated/api";
import { AddRefEventCommand, AddRefEventCommandResult } from "../../dto.generated/api";
import { UpdateRefEventCommand, UpdateRefEventCommandResult } from "../../dto.generated/api";
import { GetRefEventCommand, GetRefEventCommandResult } from "../../dto.generated/api";
import { GetRefEventImagesCommand, GetRefEventImagesCommandResult } from "../../dto.generated/api";
import { DeleteRefEventCommand, DeleteRefEventCommandResult } from "../../dto.generated/api";
import { SearchRefEventByNameCommand, SearchRefEventByNameCommandResult } from "../../dto.generated/api";
import { AddRefStayCommand, AddRefStayCommandResult } from "../../dto.generated/api";
import { GetRefStayCommand, GetRefStayCommandResult } from "../../dto.generated/api";
import { SearchRefStayByNameCommand, SearchRefStayByNameCommandResult } from "../../dto.generated/api";
import { UpdateRefStayCommand, UpdateRefStayCommandResult } from "../../dto.generated/api";
import { DeleteRefStayCommand, DeleteRefStayCommandResult } from "../../dto.generated/api";
import { AddRefRestoCommand, AddRefRestoCommandResult } from "../../dto.generated/api";
import { SearchRefRestoByNameCommand, SearchRefRestoByNameCommandResult } from "../../dto.generated/api";
import { GetRefRestoCommand, GetRefRestoCommandResult } from "../../dto.generated/api";
import { UpdateRefRestoCommand, UpdateRefRestoCommandResult } from "../../dto.generated/api";
import { DeleteRefRestoCommand, DeleteRefRestoCommandResult } from "../../dto.generated/api";
import { GetUsersCommand, GetUsersCommandResult } from "../../dto.generated/api";
import { UpdateUserCommand, UpdateUserCommandResult } from "../../dto.generated/api";
import { TestRefOliObjectIdCommand, TestRefOliObjectIdCommandResult } from "../../dto.generated/api";


@Injectable()
export class RefItemService {
  private baseUrl: string;

  constructor(
    private commandSenderService: RockItCommandSenderService,
    private http: HttpClient) { }


  // Admin-Import
  public async doImportGeonamesCountries() {
    console.log("ref-item-service:DoImportGeonamesCountries");
    const result = await this.commandSenderService.sendCommand<DoImportGeonamesCountriesCommandResult>
      (DoImportGeonamesCountriesCommand.create({}));
    return result;
  }
  public async doImportGeonamesPOI(minPopulation: number) {
    console.log("ref-item-service:DoImportGeonamesPOI");
    const result = await this.commandSenderService.sendCommand<DoImportGeonamesPOICommandResult>
      (DoImportGeonamesPOICommand.create({ minPopulation: minPopulation }));
    return result;
  }

  // Admin-repairDB
  public async testRefOliObjectId(test: number) {
    console.log("ref-item-service:testRefOliObjectId");
    const result = await this.commandSenderService.sendCommand<TestRefOliObjectIdCommandResult>
      (TestRefOliObjectIdCommand.create({ test: test }));
    return result.refOlis;
  }

  // users
  public async getUsers() {
    // console.log("getUsers:");
    const result = await this.commandSenderService.sendCommand<GetUsersCommandResult>
      (GetUsersCommand.create({}));
    return result.users;
  }
  public async updateUser(user: User) {
    // console.log("UpdateUser-user:", user);
    const result = await this.commandSenderService.sendCommand<UpdateUserCommandResult>
      (UpdateUserCommand.create({ updateUser: user }));
    return result.success;
  }

  //  refOLIs
  // public async getRefOli(refOliId: number) {
  //   const result = await this.commandSenderService.sendCommand<GetRefOliCommandResult>(GetRefOliCommand.create({refOliId}));
  //   return result.refOli;
  // }
  public async addRefOli(refOli: RefOLI) {
    // console.log("AddRefOli-RefOli:", refOli);
    const result = await this.commandSenderService.sendCommand<AddRefOLICommandResult>(AddRefOLICommand.create({ newOli: refOli }));
    return result.addedOli;
  }
  public async updateRefOli(refOli: RefOLI) {
    // console.log("UpdateRefOli-refOli:", refOli);
    const result = await this.commandSenderService.sendCommand<UpdateRefOLICommandResult>
      (UpdateRefOLICommand.create({ updateOli: refOli }));
    return result.success;
  }
  public async deleteRefOLI(oliId: number) {
    // console.log("DeleteRefOLI-oliId:", oliId);
    const result = await this.commandSenderService.sendCommand<DeleteRefOLICommandResult>(DeleteRefOLICommand.create({ refOliId: oliId }));
    return result.success;
  }


  // RefRegion
  public async getAllRefRegions(loginCode: string, maxClass: number) {
    // console.log("getAllRefRegions-maxClass:", maxClass);
    const result = await this.commandSenderService.sendCommand<GetAllRefRegionsCommandResult>
      (GetAllRefRegionsCommand.create({ loginCode: loginCode, maxClass }));
    return result.refRegions;
  }
  public async getRefRegion(refRegionId: number, maxCities: number, includeObjects: boolean) {
    // console.log("GetRefRegion-includeObjects:", includeObjects);
    const result = await this.commandSenderService.sendCommand<GetRefRegionCommandResult>
      (GetRefRegionCommand.create({ refRegionId, maxCities, includeObjects }));
    return result.refRegion;
  }
  public async getRefRegionContainers(refRegionId: number) {
    const result = await this.commandSenderService.sendCommand<GetRefRegionContainersCommandResult>
      (GetRefRegionContainersCommand.create({ refRegionId }));
    return result.refContainers;
  }
  public async getRefRegionEvents(refRegionId: number) {
    const result = await this.commandSenderService.sendCommand<GetRefRegionEventsCommandResult>
      (GetRefRegionEventsCommand.create({ refRegionId }));
    return result.refEvents;
  }
  public async getRefRegionImages(refRegionId: number, imageSelection: EnumImageSelection) {
    // console.log("getRefRegionImages-imageSelection:", imageSelection);
    const result = await this.commandSenderService.sendCommand<GetRefRegionImagesCommandResult>
      (GetRefRegionImagesCommand.create({ refRegionId, imageSelection }));
    return result.refImages;
  }
  public async getRefRegionInfos(refRegionId: number) {
    const result = await this.commandSenderService.sendCommand<GetRefRegionInfosCommandResult>
      (GetRefRegionInfosCommand.create({ refRegionId }));
    return result.refInfos;
  }
  public async getRefRegionLinks(refRegionId: number) {
    const result = await this.commandSenderService.sendCommand<GetRefRegionLinksCommandResult>
      (GetRefRegionLinksCommand.create({ refRegionId }));
    return result.refLinks;
  }
  public async getRefRegionOlis(refRegionId: number, linkType: EnumObjectType) {
    const result = await this.commandSenderService.sendCommand<GetRefRegionOlisCommandResult>
      (GetRefRegionOlisCommand.create({ refRegionId, linkType }));
    return result.refOlis;
  }
  public async getRefRegionPlaces(refRegionId: number) {
    const result = await this.commandSenderService.sendCommand<GetRefRegionPlacesCommandResult>
      (GetRefRegionPlacesCommand.create({ refRegionId }));
    return result.refPlaces;
  }
  public async searchRefRegionByName(refRegionName: string) {
    const result = await this.commandSenderService.sendCommand<SearchRefRegionByNameCommandResult>(
      SearchRefRegionByNameCommand.create({ refRegionName: refRegionName }));
    return result.refRegion;
  }
  public async getRefSubRegions(refRegionId: number) {
    const result = await this.commandSenderService.sendCommand<GetRefSubRegionsCommandResult>(
      GetRefSubRegionsCommand.create({ refRegionId }));
    return result.refSubRegions;
  }
  public async addRefRegion(refRegion: RefRegion) {
    // console.log("AddRefRegion-RefRegion:", refRegion);
    const result = await this.commandSenderService.sendCommand<AddRefRegionCommandResult>
      (AddRefRegionCommand.create({ newRefRegion: refRegion }));
    return result.addedRefRegion;
  }
  public async updateRefRegion(refRegion: RefRegion) {
    const result = await this.commandSenderService.sendCommand<UpdateRefRegionCommandResult>(
      UpdateRefRegionCommand.create({ updateRefRegion: refRegion }));
    return result.success;
  }
  public async searchRefRegionListByName(searchString: string) {
    // console.log("SearchRefRegionListByName-searchString:", searchString);
    const result = await this.commandSenderService.sendCommand<SearchRefRegionListByNameCommandResult>(
      SearchRefRegionListByNameCommand.create({ searchString }));
    return result.refRegions;
  }
  public async deleteRefRegion(regionId: number) {
    // console.log("DeleteRefRegion-regionId:", regionId);
    const result = await this.commandSenderService.sendCommand<DeleteRefRegionCommandResult>
      (DeleteRefRegionCommand.create({ refRegionId: regionId }));
    return result.success;
  }
  public async getAllPrepairedRefRegionsClassCountry() {
    // console.log("getAllPrpairedRefRegionsClassCountry");
    const result = await this.commandSenderService.sendCommand<GetAllRefRegionsPrepairedCommandResult>
      (GetAllRefRegionsPrepairedCommand.create({}));
    return result.refRegions;
  }

  // RefContainer
  getRefContainerForRegion(refRegionId: number) {
  }
  public async addRefContainer(refContainer: RefContainer) {
    // console.log("AddRefContainer-RefContainer:", refContainer);
    const result = await this.commandSenderService.sendCommand<AddRefContainerCommandResult>
      (AddRefContainerCommand.create({ newRefContainer: refContainer }));
    return result.addedRefContainer;
  }
  public async updateRefContainer(refContainer: RefContainer) {
    // console.log("UpdateRefContainer-refContainer:", refContainer);
    const result = await this.commandSenderService.sendCommand<UpdateRefContainerCommandResult>
      (UpdateRefContainerCommand.create({ updateRefContainer: refContainer }));
    return result.success;
  }
  public async getRefContainer(refContainerId: number) {
    const result = await this.commandSenderService.sendCommand<GetRefContainerCommandResult>
      (GetRefContainerCommand.create({ refContainerId }));
    return result.refContainer;
  }
  public async getRefContainerImages(refContainerId: number, imageSelection: EnumImageSelection) {
    const result = await this.commandSenderService.sendCommand<GetRefContainerImagesCommandResult>
      (GetRefContainerImagesCommand.create({ refContainerId, imageSelection }));
    return result.refImages;
  }
  public async deleteRefContainer(containerId: number) {
    // console.log("DeleteRefContainer-containerId:", containerId);
    const result = await this.commandSenderService.sendCommand<DeleteRefContainerCommandResult>
      (DeleteRefContainerCommand.create({ refContainerId: containerId }));
    return result.success;
  }

  // RefPlace
  getRefPlacesForRegion(refRegionId: number) {
  }
  public async addRefPlace(refPlace: RefPlace) {
    // console.log("AddRefPlace-RefPlace:", refPlace);
    const result = await this.commandSenderService.sendCommand<AddRefPlaceCommandResult>
      (AddRefPlaceCommand.create({ newRefPlace: refPlace }));
    return result.addedRefPlace;
  }
  public async updateRefPlace(refPlace: RefPlace) {
    // console.log("UpdateRefPlace-refPlace:", refPlace);
    const result = await this.commandSenderService.sendCommand<UpdateRefPlaceCommandResult>
      (UpdateRefPlaceCommand.create({ updateRefPlace: refPlace }));
    return result.success;
  }
  public async getRefPlace(refPlaceId: number, includeObjects: boolean) {
    const result = await this.commandSenderService.sendCommand<GetRefPlaceCommandResult>
      (GetRefPlaceCommand.create({ refPlaceId, includeObjects }));
    return result.refPlace;
  }
  public async getRefPlaceImages(refPlaceId: number, imageSelection: EnumImageSelection) {
    // console.log("getRefRegionImages-imageSelection:", imageSelection);
    const result = await this.commandSenderService.sendCommand<GetRefPlaceImagesCommandResult>
      (GetRefPlaceImagesCommand.create({ refPlaceId, imageSelection }));
    return result.refImages;
  }
  public async searchRefPlaceByName(refPlaceName: string) {
    const result = await this.commandSenderService.sendCommand<SearchRefPlaceByNameCommandResult>(
      SearchRefPlaceByNameCommand.create({ refPlaceName }));
    return result.refPlace;
  }
  public async searchRefPlaceListByName(searchString: string, refRegionId: number) {
    // console.log("SearchRefPlaceListByName-searchString:", searchString);
    const result = await this.commandSenderService.sendCommand<SearchRefPlaceListByNameCommandResult>(
      SearchRefPlaceListByNameCommand.create({ searchString, refRegionId }));
    return result.refPlaces;
  }
  public async deleteRefPlace(placeId: number) {
    // console.log("DeleteRefPlace-placeId:", placeId);
    const result = await this.commandSenderService.sendCommand<DeleteRefPlaceCommandResult>
      (DeleteRefPlaceCommand.create({ refPlaceId: placeId }));
    return result.success;
  }

  //   RefTransfer
  public async addRefTransfer(refTransfer: RefTransfer) {
    // console.log("AddRefTransfer-RefTransfer:", refTransfer);
    const result = await this.commandSenderService.sendCommand<AddRefTransferCommandResult>
      (AddRefTransferCommand.create({ newRefTransfer: refTransfer }));
    return result.addedRefTransfer;
  }
  public async getRefTransfers(refPlaceFromId: number, refPlaceToId: number) {
    // console.log("getRefTransfers:", refPlaceFromId);
    // console.log("getRefTransfers:", refPlaceToId);
    const result = await this.commandSenderService.sendCommand<GetRefTransfersCommandResult>(
      GetRefTransfersCommand.create({ fromRefPlaceId: refPlaceFromId, toRefPlaceId: refPlaceToId }));
    return result.refTransfers;
  }
  public async getRefTransfersForRegion(refRegionId: number) {
    // console.log("getRefTransfers:", refRegionId);
    const result = await this.commandSenderService.sendCommand<GetRefTransfersForRegionCommandResult>(
      GetRefTransfersForRegionCommand.create({ refRegionId: refRegionId }));
    return result.refTransfers;
  }
  public async updateRefTransfer(refTransfer: RefTransfer) {
    // console.log("UpdateRefTransfer-refTransfer:", refTransfer);
    const result = await this.commandSenderService.sendCommand<UpdateRefTransferCommandResult>
      (UpdateRefTransferCommand.create({ updateRefTransfer: refTransfer }));
    return result.success;
  }
  public async deleteRefTransfer(transferId: number) {
    // console.log("DeleteRefTransfer-transferId:", transferId);
    const result = await this.commandSenderService.sendCommand<DeleteRefTransferCommandResult>
      (DeleteRefTransferCommand.create({ refTransferId: transferId }));
    return result.success;
  }

  //  links
  public async addRefLink(refLink: RefLink) {
    // console.log("AddRefLink-RefLink:", refLink);
    const result = await this.commandSenderService.sendCommand<AddRefLinkCommandResult>(AddRefLinkCommand.create({ newLink: refLink }));
    return result.addedLink;
  }
  public async getRefLink(refLinkId: number) {
    // console.log("getRefLink-refLink:", refLinkId);
    const result = await this.commandSenderService.sendCommand<GetRefLinkCommandResult>
      (GetRefLinkCommand.create({ refLinkId: refLinkId }));
    return result.refLink;
  }
  public async updateRefLink(refLink: RefLink) {
    // console.log("UpdateRefLink-refLink:", refLink);
    const result = await this.commandSenderService.sendCommand<UpdateRefLinkCommandResult>
      (UpdateRefLinkCommand.create({ updateLink: refLink }));
    return result.success;
  }
  public async deleteRefLink(linkId: number) {
    // console.log("DeleteRefLink-linkId:", linkId);
    const result = await this.commandSenderService.sendCommand<DeleteRefLinkCommandResult>
      (DeleteRefLinkCommand.create({ refLinkId: linkId }));
    return result.success;
  }


  //  infos
  public async addRefInfo(refInfo: RefInfo) {
    // console.log("AddRefInfo-RefInfo:", refInfo);
    const result = await this.commandSenderService.sendCommand<AddRefInfoCommandResult>(AddRefInfoCommand.create({ newInfo: refInfo }));
    return result.addedInfo;
  }
  public async getRefInfo(refInfoId: number) {
    // console.log("getRefInfo-refInfo:", refInfoId);
    const result = await this.commandSenderService.sendCommand<GetRefInfoCommandResult>(GetRefInfoCommand.create({ refInfoId: refInfoId }));
    return result.refInfo;
  }
  public async updateRefInfo(refInfo: RefInfo) {
    // console.log("UpdateRefInfo-refInfo:", refInfo);
    const result = await this.commandSenderService.sendCommand<UpdateRefInfoCommandResult>
      (UpdateRefInfoCommand.create({ updateInfo: refInfo }));
    return result.success;
  }
  public async deleteRefInfo(infoId: number) {
    // console.log("DeleteRefInfo-infoId:", infoId);
    const result = await this.commandSenderService.sendCommand<DeleteRefInfoCommandResult>
      (DeleteRefInfoCommand.create({ refInfoId: infoId }));
    return result.success;
  }

  //  images
  public async addRefImage(refImage: RefImage) {
    // console.log("AddRefImage-RefImage:", refImage);
    const result = await this.commandSenderService.sendCommand<AddRefImageCommandResult>(AddRefImageCommand.create({ newImage: refImage }));
    return result.addedImage;
  }
  public async updateRefImage(refImage: RefImage) {
    // console.log("UpdateRefImage-refImage:", refImage);
    const result = await this.commandSenderService.sendCommand<UpdateRefImageCommandResult>
      (UpdateRefImageCommand.create({ updateImage: refImage }));
    return result.success;
  }
  public async getRefImage(refImageId: number) {
    // console.log("getRefImage-refImageId:", refImageId);
    const result = await this.commandSenderService.sendCommand<GetRefImageCommandResult>
      (GetRefImageCommand.create({ refImageId: refImageId }));
    return result.refImage;
  }
  public async deleteRefImage(imageId: number) {
    // console.log("DeleteRefImage-imageId:", imageId);
    const result = await this.commandSenderService.sendCommand<DeleteRefImageCommandResult>
      (DeleteRefImageCommand.create({ refImageId: imageId }));
    return result.success;
  }

  //  events
  public async addRefEvent(refEvent: RefEvent) {
    // console.log("addRefEvent-RefEvent:", refEvent);
    const result = await this.commandSenderService.sendCommand<AddRefEventCommandResult>
      (AddRefEventCommand.create({ newEvent: refEvent }));
    return result.addedRefEvent;
  }
  public async updateRefEvent(refEvent: RefEvent) {
    // console.log("updateRefEvent-refEvent:", refEvent);
    const result = await this.commandSenderService.sendCommand<UpdateRefEventCommandResult>
      (UpdateRefEventCommand.create({ updateEvent: refEvent }));
    return result.success;
  }
  public async getRefEvent(refEventId: number) {
    // console.log("getRefEvent-refEvent:", refEventId);
    const result = await this.commandSenderService.sendCommand<GetRefEventCommandResult>
      (GetRefEventCommand.create({ refEventId: refEventId }));
    return result.refEvent;
  }
  public async getRefEventImages(refEventId: number, imageSelection: EnumImageSelection) {
    // console.log("getRefEventImages-imageSelection:", imageSelection);
    const result = await this.commandSenderService.sendCommand<GetRefEventImagesCommandResult>
      (GetRefEventImagesCommand.create({ refEventId, imageSelection }));
    return result.refImages;
  }
  public async searchRefEventByName(refEventName: string) {
    const result = await this.commandSenderService.sendCommand<SearchRefEventByNameCommandResult>(
      SearchRefEventByNameCommand.create({ refEventName }));
    return result.refEvent;
  }
  public async deleteRefEvent(refEventId: number) {
    // console.log("deleteRefEvent-eventId:", refEventId);
    const result = await this.commandSenderService.sendCommand<DeleteRefEventCommandResult>
      (DeleteRefEventCommand.create({ refEventId: refEventId }));
    return result.success;
  }

  //  stays
  public async addRefStay(refStay: RefStay) {
    // console.log("AddRefStay-refStay:", refStay);
    const result = await this.commandSenderService.sendCommand<AddRefStayCommandResult>
      (AddRefStayCommand.create({ newStay: refStay }));
    return result.addedStay;
  }
  public async updateRefStay(refStay: RefStay) {
    // console.log("UpdateRefStay-refStay:", refStay);
    const result = await this.commandSenderService.sendCommand<UpdateRefStayCommandResult>
      (UpdateRefStayCommand.create({ updateStay: refStay }));
    return result.success;
  }
  public async getRefStay(refStayId: number) {
    // console.log("getRefStay-refStay:", refStayId);
    const result = await this.commandSenderService.sendCommand<GetRefStayCommandResult>
      (GetRefStayCommand.create({ refStayId: refStayId }));
    return result.refStay;
  }
  public async searchRefStayByName(refStayName: string) {
    const result = await this.commandSenderService.sendCommand<SearchRefStayByNameCommandResult>(
      SearchRefStayByNameCommand.create({ refStayName }));
    return result.refStay;
  }
  public async deleteRefStay(stayId: number) {
    // console.log("DeleteRefStay-stayId:", stayId);
    const result = await this.commandSenderService.sendCommand<DeleteRefStayCommandResult>
      (DeleteRefStayCommand.create({ refStayId: stayId }));
    return result.success;
  }


  //  restos
  public async addRefResto(refResto: RefResto) {
    // console.log("AddRefResto-refResto:", refResto);
    const result = await this.commandSenderService.sendCommand<AddRefRestoCommandResult>
      (AddRefRestoCommand.create({ newResto: refResto }));
    return result.addedResto;
  }
  public async updateRefResto(refResto: RefResto) {
    // console.log("UpdateRefResto-refResto:", refResto);
    const result = await this.commandSenderService.sendCommand<UpdateRefRestoCommandResult>
      (UpdateRefRestoCommand.create({ updateResto: refResto }));
    return result.success;
  }
  public async getRefResto(refRestoId: number) {
    // console.log("getRefResto-refEResto:", refRestoId);
    const result = await this.commandSenderService.sendCommand<GetRefRestoCommandResult>
      (GetRefRestoCommand.create({ refRestoId: refRestoId }));
    return result.refResto;
  }
  public async searchRefRestoByName(refRestoName: string) {
    const result = await this.commandSenderService.sendCommand<SearchRefRestoByNameCommandResult>(
      SearchRefRestoByNameCommand.create({ refRestoName: refRestoName }));
    return result.refResto;
  }
  public async deleteRefResto(restoId: number) {
    // console.log("DeleteRefResto-restoId:", restoId);
    const result = await this.commandSenderService.sendCommand<DeleteRefRestoCommandResult>
      (DeleteRefRestoCommand.create({ refRestoId: restoId }));
    return result.success;
  }

  // export data
  exportRefData() {
    // console.log("ExportRefData:");
    // const url = `${this.baseUrl}api/refitems/ExportRefData`;
    // console.log("ExportRefData-URL:", url);
    // return this.http.post(url, "");
  }

  // import data
  importRefData() {
    // console.log("ImportRefData:");
    // const url = `${this.baseUrl}api/refitems/ImportRefData`;
    // console.log("ImportRefData-URL:", url);
    // return this.http.post(url, "");
  }

}
