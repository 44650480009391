import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "", redirectTo: "/planner",
    pathMatch: "full"
  },
  {
    path: "planner",
    loadChildren: () => import("./module-trips-home/trips-home.module").then(mod => mod.TripsHomeModule),
  },
  {
    path: "trips",
    loadChildren: () => import("./module-trips-home/trips-home.module").then(mod => mod.TripsHomeModule),
  },
  {
    path: "triplist",
    loadChildren: () => import("./module-trips/trips.module").then(mod => mod.TripsModule),
  },
  {
    path: "triplist/share/:key",
    loadChildren: () => import("./module-trips/trips.module").then(mod => mod.TripsModule),
  },
  {
    path: "examples",
    loadChildren: () => import("./module-examples/examples.module").then(mod => mod.ExamplesModule),
  },
  {
    path: "explore",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "region/:regionname",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "regionId/:regionId",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "place/:placename",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "placeId/:placeId",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "event/:eventname",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "eventId/:eventId",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "stay/:stayname",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "stayId/:stayId",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "restoId/:restoId",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "resto/:restoname",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "containerId/:containerId",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "admin",
    loadChildren: () => import("./module-admin/admin.module").then(mod => mod.AdminModule),
  },
  {
    path: "maps",
    loadChildren: () => import("./module-maps/maps.module").then(mod => mod.MapsModule),
  },
  // {
  //   path: "maps/route/:movetype/:start/:end",
  //   loadChildren: () => import("./module-maps/maps.module").then(mod => mod.MapsModule),
  // },
  {
    path: "maps/param",   // ver, partnerId, name, movetype, start, wp, end, roundtrip, markers, help
    loadChildren: () => import("./module-maps/maps.module").then(mod => mod.MapsModule),
  },
  // {
  //   path: "maps/:showroute",
  //   loadChildren: () => import("./module-maps/maps.module").then(mod => mod.MapsModule),
  // },
  {
    path: "t4y",
    loadChildren: () => import("./module-trips-footer-pages/trips-footer-pages.module").then(mod => mod.FooterPagesModule),
  },
  {
    path: "test",
    loadChildren: () => import("./module-test/test.module").then(mod => mod.TestModule),
  },
  {
    // all other routes
    path: "**",
    redirectTo: "/planner", pathMatch: "full",
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: "enabled" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


