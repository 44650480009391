
import { EnumDutyStatus, EnumDutyType, Duty, Container, Event, Place, Stay, Resto, Trip, Transfer } from "src/dto.generated/api";

export function createNewDuty() {
  const newDuty: Duty = <Duty>{};
  newDuty.order = 1;
  newDuty.priority = 1;
  return newDuty;
}


export function cloneDuty(duty: Duty) {
  const newDuty: Duty = <Duty>{};
  newDuty.id = duty.id;
  newDuty.order = duty.order;
  newDuty.dutyType = duty.dutyType;
  newDuty.title = duty.title;
  newDuty.text = duty.text;
  newDuty.from = duty.from;
  newDuty.to = duty.to;
  newDuty.duration = duty.duration;
  newDuty.containerId = duty.containerId;
  newDuty.eventId = duty.eventId;
  newDuty.placeId = duty.placeId;
  newDuty.restoId = duty.restoId;
  newDuty.stayId = duty.stayId;
  newDuty.transferId = duty.transferId;
  newDuty.tripId = duty.tripId;
  return newDuty;
}

export function setDutyTypeText(dutyType: EnumDutyType) {

  // dutyTypeText
  let dutyTypeText = "?";
  if (dutyType === EnumDutyType.call) { dutyTypeText = "call"; }
  if (dutyType === EnumDutyType.sendmail) { dutyTypeText = "sendmail"; }
  if (dutyType === EnumDutyType.waitformail) { dutyTypeText = "wait for mail"; }
  if (dutyType === EnumDutyType.book) { dutyTypeText = "book"; }
  if (dutyType === EnumDutyType.buy) { dutyTypeText = "buy"; }
  if (dutyType === EnumDutyType.organise) { dutyTypeText = "organise"; }
  if (dutyType === EnumDutyType.todo) { dutyTypeText = "todo"; }
  if (dutyType === EnumDutyType.other) { dutyTypeText = "other"; }
  return dutyTypeText;
}

export function setDutyStatusText(dutyStatus: EnumDutyStatus) {

  // dutyStatusText
  let dutyStatusText = "?";
  if (dutyStatus === EnumDutyStatus.wait) { dutyStatusText = "wait"; }
  if (dutyStatus === EnumDutyStatus.active) { dutyStatusText = "active"; }
  if (dutyStatus === EnumDutyStatus.critical) { dutyStatusText = "critical"; }
  if (dutyStatus === EnumDutyStatus.finished) { dutyStatusText = "done"; }
  return dutyStatusText;
}

// export function getNewDutyId(duties: Array<Duty>) {
//   let id = 0;
//   for (const xDuty of duties) {
//     if (xDuty.id < id) { id = xDuty.id; }
//   }
//   id--;
//   return id;
// }

export function convertDutyDates(duty: Duty) {
  duty.from = new Date(duty.from);
  duty.to = new Date(duty.to);
}


export function isDutyInContainer(duty: Duty, container: Container) {

  // test all duties directly in container
  for (const xDuty of container.duties) {
    if (duty.id === xDuty.id) {
      return true;
    }
  }
  // test all child-containers of container
  for (const xContainer of container.containers) {
    if (isDutyInContainer(duty, xContainer)) { return true; }
  }
  // test all child-places of container
  for (const xPlace of container.places) {
    if (isDutyInPlace(duty, xPlace)) { return true; }
  }
  // test all child-events of place
  for (const xEvent of container.events) {
    if (isDutyInEvent(duty, xEvent)) { return true; }
  }
  // test all child-stays of place
  for (const xStay of container.stays) {
    if (isDutyInStay(duty, xStay)) { return true; }
  }
  // test all child-restos of place
  for (const xResto of container.restos) {
    if (isDutyInResto(duty, xResto)) { return true; }
  }
  return false;
}

export function isDutyInEvent(duty: Duty, event: Event) {

  // test all dutys directly in event
  for (const xDuty of event.duties) {
    if (duty.id === xDuty.id) {
      return true;
    }
  }
  return false;
}
export function isDutyInTransfer(duty: Duty, transfer: Transfer) {

  // test all duties directly in transfer
  for (const xDuty of transfer.duties) {
    if (duty.id === xDuty.id) {
      return true;
    }
  }
  return false;
}

export function isDutyInPlace(duty: Duty, place: Place) {

  // test all dutys directly in place
  for (const xDuty of place.duties) {
    if (duty.id === xDuty.id) {
      return true;
    }
  }
  // test all child-containers of place
  for (const xContainer of place.containers) {
    if (isDutyInContainer(duty, xContainer)) { return true; }
  }
  // test all child-places of place
  for (const xPlace of place.places) {
    if (isDutyInPlace(duty, xPlace)) { return true; }
  }
  // test all child-events of place
  for (const xEvent of place.events) {
    if (isDutyInEvent(duty, xEvent)) { return true; }
  }
  // test all child-stays of place
  for (const xStay of place.stays) {
    if (isDutyInStay(duty, xStay)) { return true; }
  }
  // test all child-restos of place
  for (const xResto of place.restos) {
    if (isDutyInResto(duty, xResto)) { return true; }
  }
  return false;
}


export function isDutyInStay(duty: Duty, stay: Stay) {

  // test all dutys directly in stay
  for (const xDuty of stay.duties) {
    if (duty.id === xDuty.id) {
      return true;
    }
  }
  return false;
}
export function isDutyInResto(duty: Duty, resto: Resto) {

  // test all dutys directly in resto
  for (const xDuty of resto.duties) {
    if (duty.id === xDuty.id) {
      return true;
    }
  }
  return false;
}

export function getMinLocalDutyIdForTrip(inTrip: Trip) {
  let id = 0;
  // search in trip-containers
  for (const xContainer of inTrip.containers) {
    const dutyId = getMinLocalDutyIdInContainer(xContainer);
    if (dutyId < id) { id = dutyId; }
  }
  // search in trip-events
  for (const xEvent of inTrip.events) {
    const dutyId = getMinLocalDutyIdInEvent(xEvent);
    if (dutyId < id) { id = dutyId; }
  }
  // search in trip-places
  for (const xPlace of inTrip.places) {
    const dutyId = getMinLocalDutyIdInPlace(xPlace);
    if (dutyId < id) { id = dutyId; }
  }
  // search in trip-restos
  for (const xResto of inTrip.restos) {
    const edutyId = getMinLocalDutyIdInResto(xResto);
    if (edutyId < id) { id = edutyId; }
  }
  // search in trip-stays
  for (const xStay of inTrip.stays) {
    const dutyId = getMinLocalDutyIdInStay(xStay);
    if (dutyId < id) { id = dutyId; }
  }
  // search in trip-transfers
  for (const xTransfer of inTrip.transfers) {
    const dutyId = getMinLocalDutyIdInTransfer(xTransfer);
    if (dutyId < id) { id = dutyId; }
  }
  return id;
}
export function getMinLocalDutyIdInContainer(inContainer: Container) {
  // console.log("EventFind:getPlaceInContainer-placeId", placeId);
  let id = 0;
  // test duties of container
  for (const xDuty of inContainer.duties) {
    if (xDuty.id < id) { id = xDuty.id; }
  }
  // search in container-containers
  for (const xContainer of inContainer.containers) {
    const edutyId = getMinLocalDutyIdInContainer(xContainer);
    if (edutyId < id) { id = edutyId; }
  }
  // search in container-events
  for (const xEvent of inContainer.events) {
    const dutyId = getMinLocalDutyIdInEvent(xEvent);
    if (dutyId < id) { id = dutyId; }
  }
  // search in container-places
  for (const xPlace of inContainer.places) {
    const dutyId = getMinLocalDutyIdInPlace(xPlace);
    if (dutyId < id) { id = dutyId; }
  }
  // search in container-restos
  for (const xResto of inContainer.restos) {
    const dutyId = getMinLocalDutyIdInResto(xResto);
    if (dutyId < id) { id = dutyId; }
  }
  // search in container-stays
  for (const xStay of inContainer.stays) {
    const dutyId = getMinLocalDutyIdInStay(xStay);
    if (dutyId < id) { id = dutyId; }
  }
  // search in trip-transfers
  for (const xTransfer of inContainer.transfers) {
    const dutyId = getMinLocalDutyIdInTransfer(xTransfer);
    if (dutyId < id) { id = dutyId; }
  }
  return id;
}
export function getMinLocalDutyIdInEvent(inEvent: Event) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test duties of event
  for (const xDuty of inEvent.duties) {
    if (xDuty.id < id) { id = xDuty.id; }
  }
  return id;
}
export function getMinLocalDutyIdInPlace(inPlace: Place) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test duties of place
  for (const xDuty of inPlace.duties) {
    if (xDuty.id < id) { id = xDuty.id; }
  }
  // search in place-containers
  for (const xContainer of inPlace.containers) {
    const dutyId = getMinLocalDutyIdInContainer(xContainer);
    if (dutyId < id) { id = dutyId; }
  }
  // search in place-events
  for (const xEvent of inPlace.events) {
    const dutyId = getMinLocalDutyIdInEvent(xEvent);
    if (dutyId < id) { id = dutyId; }
  }
  // search in place-places
  for (const xPlace of inPlace.places) {
    const dutyId = getMinLocalDutyIdInPlace(xPlace);
    if (dutyId < id) { id = dutyId; }
  }
  // search in place-restos
  for (const xResto of inPlace.restos) {
    const dutyId = getMinLocalDutyIdInResto(xResto);
    if (dutyId < id) { id = dutyId; }
  }
  // search in place-stays
  for (const xStay of inPlace.stays) {
    const dutyId = getMinLocalDutyIdInStay(xStay);
    if (dutyId < id) { id = dutyId; }
  }
  return id;
}
export function getMinLocalDutyIdInResto(inResto: Resto) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test duties of event
  for (const xDuty of inResto.duties) {
    if (xDuty.id < id) { id = xDuty.id; }
  }
  return id;
}
export function getMinLocalDutyIdInStay(inStay: Stay) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test duties of event
  for (const xDuty of inStay.duties) {
    if (xDuty.id < id) { id = xDuty.id; }
  }
  return id;
}
export function getMinLocalDutyIdInTransfer(inTransfer: Transfer) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test duties of transfer
  for (const xDuty of inTransfer.duties) {
    if (xDuty.id < id) { id = xDuty.id; }
  }
  return id;
}



export function isDutyPrivate(duty: Duty, inTrip: Trip) {
  for (const xContainer of inTrip.containers) {
    if (!xContainer.userId) { continue; }
    if (isDutyInContainer(duty, xContainer)) { return true; }
  }
  return false;
}

