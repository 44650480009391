
import {
  Container, Place, Transfer, Event, Stay, Resto,
  Link, Info, Image, Duty, Notice, XDoc, RefContainer, Trip, RefRegion, EnumContainerType
} from "src/dto.generated/api";
import { ItemObject } from "../models/item-object";
import { isDutyInContainer } from "./utils-duty";
import { isEventInContainer } from "./utils-event";
import { isImageInContainer } from "./utils-image";
import { isInfoInContainer } from "./utils-info";
import { isLinkInContainer } from "./utils-link";
import { isNoticeInContainer } from "./utils-notice";
import { getPlaceInTrip, getPlaceParentContainerForTrip, getPlaceParentPlaceForTrip, isPlaceInContainer } from "./utils-place";
import { isRestoInContainer } from "./utils-resto";
import { isStayInContainer } from "./utils-stay";
import { isTransferInContainer } from "./utils-transfer";
import { isXDocInContainer } from "./utils-xdoc";


export function createNewContainer() {
  const newContainer: Container = <Container>{};
  newContainer.order = 1;
  newContainer.containers = new Array<Container>();
  newContainer.events = new Array<Event>();
  newContainer.duties = new Array<Duty>();
  newContainer.images = new Array<Image>();
  newContainer.infos = new Array<Info>();
  newContainer.links = new Array<Link>();
  newContainer.notices = new Array<Notice>();
  newContainer.places = new Array<Place>();
  newContainer.stays = new Array<Stay>();
  newContainer.restos = new Array<Resto>();
  newContainer.transfers = new Array<Transfer>();
  newContainer.xdocs = new Array<XDoc>();
  return newContainer;
}

export function cloneContainer(container: Container) {
  const newContainer: Container = <Container>{};
  newContainer.id = container.id;
  newContainer.title = container.title;
  newContainer.order = container.order;
  newContainer.containerId = container.containerId;
  newContainer.placeId = container.placeId;
  newContainer.tripId = container.tripId;
  newContainer.containers = new Array<Container>();
  newContainer.events = new Array<Event>();
  newContainer.duties = new Array<Duty>();
  newContainer.images = new Array<Image>();
  newContainer.infos = new Array<Info>();
  newContainer.links = new Array<Link>();
  newContainer.notices = new Array<Notice>();
  newContainer.places = new Array<Place>();
  newContainer.stays = new Array<Stay>();
  newContainer.restos = new Array<Resto>();
  newContainer.transfers = new Array<Transfer>();
  newContainer.xdocs = new Array<XDoc>();
  return newContainer;
}

export function createNewContainerFromRefContainer(refContainer: RefContainer) {
  const newContainer = createNewContainer();
  newContainer.refContainerId = refContainer.id;
  newContainer.title = refContainer.title;
  return newContainer;
}

export function createNewContainerFromRefRegion(refRegion: RefRegion) {
  const newContainer = createNewContainer();
  newContainer.title = refRegion.name;
  newContainer.refRegionId = refRegion.id;
  return newContainer;
}

// export function getNewContainerId(containers: Array<Container>) {
//   let id = 0;
//   for (const xContainer of containers) {
//     if (xContainer.id < id) { id = xContainer.id; }
//   }
//   id--;
//   return id;
// }

export function isContainerPrivate(container: Container, inTrip: Trip) {
  if (container.userId) { return (true); }
  for (const xContainer of inTrip.containers) {
    if (!xContainer.userId) { continue; }
    if (isContainerInContainer(container, xContainer)) { return true; }
  }
  return false;
}
export function isItemInContainer(item: ItemObject, container: Container) {
  if (item.itemType === "Container") {
    if (isContainerInContainer(item.item, container)) { return true; }
  }
  if (item.itemType === "Event") {
    if (isEventInContainer(item.item, container)) { return true; }
  }
  if (item.itemType === "Duty") {
    if (isDutyInContainer(item.item, container)) { return true; }
  }
  if (item.itemType === "Image") {
    if (isImageInContainer(item.item, container)) { return true; }
  }
  if (item.itemType === "Info") {
    if (isInfoInContainer(item.item, container)) { return true; }
  }
  if (item.itemType === "Link") {
    if (isLinkInContainer(item.item, container)) { return true; }
  }
  if (item.itemType === "Notice") {
    if (isNoticeInContainer(item.item, container)) { return true; }
  }
  if (item.itemType === "Place") {
    if (isPlaceInContainer(item.item, container)) { return true; }
  }
  if (item.itemType === "Resto") {
    if (isRestoInContainer(item.item, container)) { return true; }
  }
  if (item.itemType === "Stay") {
    if (isStayInContainer(item.item, container)) { return true; }
  }
  if (item.itemType === "Transfer") {
    if (isTransferInContainer(item.item, container)) { return true; }
  }
  if (item.itemType === "XDoc") {
    if (isXDocInContainer(item.item, container)) { return true; }
  }
}
export function isContainerInContainer(container: Container, inContainer: Container) {

  for (const xContainer of inContainer.containers) {
    if (xContainer.id === container.id) { return true; }
    if (isContainerInContainer(container, xContainer)) { return true; }
  }
  for (const xPlace of inContainer.places) {
    if (isContainerInPlace(container, xPlace)) { return true; }
  }
  return false;
}
export function isContainerInPlace(container: Container, inPlace: Place) {

  for (const xContainer of inPlace.containers) {
    if (xContainer.id === container.id) { return true; }
    if (isContainerInContainer(container, xContainer)) { return true; }
  }
  for (const xPlace of inPlace.places) {
    if (isContainerInPlace(container, xPlace)) { return true; }
  }
  return false;
}

export function getContainerInTrip(containerId: number, inTrip: Trip) {
  // console.log("EventFind:getContainerOfTrip-containerId", containerId);

  // test containers of trip
  for (const xContainer of inTrip.containers) {
    if (xContainer.id === containerId) { return xContainer; }
  }
  // search in containers
  for (const xContainer of inTrip.containers) {
    const container = getContainerInContainer(containerId, xContainer);
    if (container) { return container; }
  }
  // search in places
  for (const xPlace of inTrip.places) {
    const container = getContainerInPlace(containerId, xPlace);
    if (container) { return container; }
  }
}
export function getContainerInContainer(containerId: number, inContainer: Container) {

  // test containers of containers
  for (const xContainer of inContainer.containers) {
    if (xContainer.id === containerId) { return xContainer; }
  }
  // search in containers
  for (const xContainer of inContainer.containers) {
    const container = getContainerInContainer(containerId, xContainer);
    if (container) { return container; }
  }
  // search in places
  for (const xPlace of inContainer.places) {
    const container = getContainerInPlace(containerId, xPlace);
    if (container) { return container; }
  }
}
export function getContainerInPlace(containerId: number, inPlace: Place) {

  // test containers of place
  for (const xContainer of inPlace.containers) {
    if (xContainer.id === containerId) { return xContainer; }
  }
  // search in containers
  for (const xContainer of inPlace.containers) {
    const container = getContainerInContainer(containerId, xContainer);
    if (container) { return container; }
  }
  // search in places
  for (const xPlace of inPlace.places) {
    const container = getContainerInPlace(containerId, xPlace);
    if (container) { return container; }
  }
}

export function getMinLocalContainerIdForTrip(inTrip: Trip) {
  let id = 0;
  // search in trip-containers
  for (const xContainer of inTrip.containers) {
    const containerId = getMinLocalContainerIdInContainer(xContainer);
    if (containerId < id) { id = containerId; }
  }
  // search in trip-places
  for (const xPlace of inTrip.places) {
    const containerId = getMinLocalContainerIdInPlace(xPlace);
    if (containerId < id) { id = containerId; }
  }
  return id;
}
export function getMinLocalContainerIdInContainer(inContainer: Container) {
  let id = 0;
  // test container
  if (inContainer.id < id) { id = inContainer.id; }
  // search in container-containers
  for (const xContainer of inContainer.containers) {
    const containerId = getMinLocalContainerIdInContainer(xContainer);
    if (containerId < id) { id = containerId; }
  }
  // search in container-places
  for (const xPlace of inContainer.places) {
    const containerId = getMinLocalContainerIdInPlace(xPlace);
    if (containerId < id) { id = containerId; }
  }
  return id;
}
export function getMinLocalContainerIdInPlace(inPlace: Place) {
  let id = 0;
  // search in place-containers
  for (const xContainer of inPlace.containers) {
    const containerId = getMinLocalContainerIdInContainer(xContainer);
    if (containerId < id) { id = containerId; }
  }
  // search in place-places
  for (const xPlace of inPlace.places) {
    const containerId = getMinLocalContainerIdInPlace(xPlace);
    if (containerId < id) { id = containerId; }
  }
  return id;
}


export function getContainerParentContainerForTrip(container: Container, trip: Trip, onlyForRefRegion: boolean) {
  // console.log("utils-container:getContainerParentContainerForTrip-container", container);

  if (container.containerId) {
    const parentContainer = getContainerInTrip(container.containerId, trip);
    if (!onlyForRefRegion) { return parentContainer; }
    if (parentContainer.refRegionId) { return parentContainer; }
    const resContainer = getContainerParentContainerForTrip(parentContainer, trip, onlyForRefRegion);
    if (resContainer) { return resContainer; }
  }
  if (container.placeId) {
    const parentPlace = getPlaceInTrip(container.placeId, trip);
    if (parentPlace && !onlyForRefRegion) { return parentPlace; }
    if (parentPlace && parentPlace.refRegionId) { return parentPlace; }
    const resContainer = getPlaceParentContainerForTrip(parentPlace, trip, onlyForRefRegion);
    if (resContainer) { return resContainer; }
  }
}

export function getContainerParentPlaceForTrip(container: Container, trip: Trip, onlyForRefPlace: boolean) {
  console.log("utils-container:getContainerParentPlaceForTrip-container", container);

  if (container.containerId) {
    const parentContainer = getContainerInTrip(container.containerId, trip);
    const resPlace = getContainerParentPlaceForTrip(parentContainer, trip, onlyForRefPlace);
    if (resPlace) { return resPlace; }
  }
  if (container.placeId) {
    const parentPlace = getPlaceInTrip(container.placeId, trip);
    if (!onlyForRefPlace) { return parentPlace; }
    if (parentPlace.refRegionId) { return parentPlace; }
    const resPlace = getPlaceParentPlaceForTrip(parentPlace, trip, onlyForRefPlace);
    if (resPlace) { return resPlace; }
  }
}
export function getContainerExtent(container: Container) {
  let minX = Number.MAX_VALUE;
  let maxX = -Number.MAX_VALUE;
  let minY = Number.MAX_VALUE;
  let maxY = -Number.MAX_VALUE;
  for (const xPlace of container.places) {
    if (xPlace.zoom) {
      if (xPlace.coordLon < minX) { minX = xPlace.coordLon; }
      if (xPlace.coordLon > maxX) { maxX = xPlace.coordLon; }
      if (xPlace.coordLat < minY) { minY = xPlace.coordLat; }
      if (xPlace.coordLat > maxY) { maxY = xPlace.coordLat; }
    }
  }
  for (const xEvent of container.events) {
    if (xEvent.zoom) {
      if (xEvent.coordLon < minX) { minX = xEvent.coordLon; }
      if (xEvent.coordLon > maxX) { maxX = xEvent.coordLon; }
      if (xEvent.coordLat < minY) { minY = xEvent.coordLat; }
      if (xEvent.coordLat > maxY) { maxY = xEvent.coordLat; }
    }
  }
  for (const xResto of container.restos) {
    if (xResto.zoom) {
      if (xResto.coordLon < minX) { minX = xResto.coordLon; }
      if (xResto.coordLon > maxX) { maxX = xResto.coordLon; }
      if (xResto.coordLat < minY) { minY = xResto.coordLat; }
      if (xResto.coordLat > maxY) { maxY = xResto.coordLat; }
    }
  }
  for (const xStay of container.stays) {
    if (xStay.zoom) {
      if (xStay.coordLon < minX) { minX = xStay.coordLon; }
      if (xStay.coordLon > maxX) { maxX = xStay.coordLon; }
      if (xStay.coordLat < minY) { minY = xStay.coordLat; }
      if (xStay.coordLat > maxY) { maxY = xStay.coordLat; }
    }
  }
  for (const xTransfer of container.transfers) {
    if (xTransfer.coordFromLon) {
      if (xTransfer.coordFromLon < minX) { minX = xTransfer.coordFromLon; }
      if (xTransfer.coordFromLon > maxX) { maxX = xTransfer.coordFromLon; }
      if (xTransfer.coordFromLat < minY) { minY = xTransfer.coordFromLat; }
      if (xTransfer.coordFromLat > maxY) { maxY = xTransfer.coordFromLat; }
    }
    if (xTransfer.coordToLon) {
      if (xTransfer.coordToLon < minX) { minX = xTransfer.coordToLon; }
      if (xTransfer.coordToLon > maxX) { maxX = xTransfer.coordToLon; }
      if (xTransfer.coordToLat < minY) { minY = xTransfer.coordToLat; }
      if (xTransfer.coordToLat > maxY) { maxY = xTransfer.coordToLat; }
    }
  }
  const extent = [minX, minY, maxX, maxY];
  return extent;
}

