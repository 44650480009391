
import { Container, EnumImageSourceType, Event, Image, Place, RefImage, Resto, Stay, Transfer, Trip } from "src/dto.generated/api";



export function createNewImage() {
  const newImage: Image = <Image>{};
  newImage.order = 1;
  return newImage;
}

export function createNewImageFromRefImage(refImage: RefImage) {
  const newImage = createNewImage();
  newImage.refImageId = refImage.id;
  newImage.title = refImage.title;
  newImage.providerName = refImage.providerName;
  newImage.url = refImage.url;
  newImage.description = refImage.description;
  newImage.refImageId = refImage.id;
  return newImage;
}


export function isImageInContainer(info: Image, container: Container) {

  // test all infos directly in container
  for (const xImage of container.images) {
    if (info.id === xImage.id) {
      return true;
    }
  }
  // test all child-containers of container
  for (const xContainer of container.containers) {
    if (isImageInContainer(info, xContainer)) { return true; }
  }
  // test all child-places of container
  for (const xPlace of container.places) {
    if (isImageInPlace(info, xPlace)) { return true; }
  }
  // test all child-events of place
  for (const xEvent of container.events) {
    if (isImageInEvent(info, xEvent)) { return true; }
  }
  // test all child-stays of place
  for (const xStay of container.stays) {
    if (isImageInStay(info, xStay)) { return true; }
  }
  // test all child-restos of place
  for (const xResto of container.restos) {
    if (isImageInResto(info, xResto)) { return true; }
  }
  return false;
}

export function isImageInPlace(image: Image, place: Place) {

  // test all infos directly in place
  for (const xImage of place.images) {
    if (image.id === xImage.id) {
      return true;
    }
  }
  // test all child-containers of place
  for (const xContainer of place.containers) {
    if (isImageInContainer(image, xContainer)) { return true; }
  }
  // test all child-places of place
  for (const xPlace of place.places) {
    if (isImageInPlace(image, xPlace)) { return true; }
  }
  // test all child-events of place
  for (const xEvent of place.events) {
    if (isImageInEvent(image, xEvent)) { return true; }
  }
  // test all child-stays of place
  for (const xStay of place.stays) {
    if (isImageInStay(image, xStay)) { return true; }
  }
  // test all child-restos of place
  for (const xResto of place.restos) {
    if (isImageInResto(image, xResto)) { return true; }
  }
  return false;
}

export function isImageInEvent(image: Image, event: Event) {

  // test all infos directly in event
  for (const xImage of event.images) {
    if (image.id === xImage.id) {
      return true;
    }
  }
  return false;
}

export function isImageInStay(image: Image, stay: Stay) {

  // test all infos directly in stay
  for (const xImage of stay.images) {
    if (image.id === xImage.id) {
      return true;
    }
  }
  return false;
}

export function isImageInResto(image: Image, resto: Resto) {

  // test all infos directly in resto
  for (const xImage of resto.images) {
    if (image.id === xImage.id) {
      return true;
    }
  }
  return false;
}

export function isImageInTransfer(image: Image, transfer: Transfer) {

  // test all images directly in transfer
  for (const xImage of transfer.images) {
    if (image.id === xImage.id) {
      return true;
    }
  }
  return false;
}

export function isImagePrivate(image: Image, inTrip: Trip) {
  for (const xContainer of inTrip.containers) {
    if (!xContainer.userId) { continue; }
    if (isImageInContainer(image, xContainer)) { return true; }
  }
  return false;
}
