
import { Resto, Link, Info, Image, Duty, Notice, XDoc, EnumRestoType, EnumFoodType, Trip, Container, Place } from "src/dto.generated/api";
import { RefResto } from "src/dto.generated/api";
import { ItemObject } from "../models/item-object";
import { getContainerInTrip, getContainerParentContainerForTrip, getContainerParentPlaceForTrip } from "./utils-container";
import { isDutyInResto } from "./utils-duty";
import { isImageInResto } from "./utils-image";
import { isInfoInResto } from "./utils-info";
import { isLinkInResto } from "./utils-link";
import { isNoticeInResto } from "./utils-notice";
import { getPlaceInTrip, getPlaceParentContainerForTrip, getPlaceParentPlaceForTrip } from "./utils-place";



export function createNewResto() {
  const newResto: Resto = <Resto>{};
  newResto.order = 1;
  newResto.visitAt = new Date(Date.UTC(0, 0, 1));
  newResto.dateActive = 1;
  newResto.links = new Array<Link>();
  newResto.infos = new Array<Info>();
  newResto.images = new Array<Image>();
  newResto.duties = new Array<Duty>();
  newResto.notices = new Array<Notice>();
  // newPlace.xDoc = new Array<XDoc>();
  return newResto;
}

export function cloneResto(resto: Resto) {
  const newResto: Resto = <Resto>{};
  newResto.id = resto.id;
  newResto.order = resto.order;
  newResto.name = resto.name;
  newResto.description = resto.description;
  newResto.descUrl = resto.descUrl;
  newResto.restoType = resto.restoType;
  newResto.foodType = resto.foodType;
  newResto.address = resto.address;
  newResto.tel = resto.tel;
  newResto.email = resto.email;
  newResto.containerId = resto.containerId;
  newResto.placeId = resto.placeId;
  newResto.tripId = resto.tripId;
  return newResto;
}

export function createNewRestoFromRefResto(refResto: RefResto) {
  const newResto = createNewResto();
  newResto.name = refResto.name;
  newResto.refRestoId = refResto.id;
  if (refResto.restoType !== undefined) { newResto.restoType = refResto.restoType; }
  if (refResto.foodType !== undefined) { newResto.foodType = refResto.foodType; }
  if (refResto.priceCategory !== undefined) { newResto.priceCategory = refResto.priceCategory; }
  if (refResto.rating !== undefined) { newResto.rating = refResto.rating; }
  if (refResto.description !== undefined) { newResto.description = refResto.description; }
  if (refResto.descUrl !== undefined) { newResto.descUrl = refResto.descUrl; }
  if (refResto.tel !== undefined) { newResto.tel = refResto.tel; }
  if (refResto.address !== undefined) { newResto.address = refResto.address; }
  if (refResto.email !== undefined) { newResto.email = refResto.email; }
  if (refResto.specials !== undefined) { newResto.specials = refResto.specials; }
  if (refResto.coordLon !== undefined) { newResto.coordLon = refResto.coordLon; }
  if (refResto.coordLat !== undefined) { newResto.coordLat = refResto.coordLat; }
  if (refResto.zoom !== undefined) { newResto.zoom = refResto.zoom; }
  return newResto;
}

// export function getNewRestoId(restos: Array<Resto>) {
//   let id = 0;
//   for (const xResto of restos) {
//     if (xResto.id < id) { id = xResto.id; }
//   }
//   id--;
//   return id;
// }

export function convertRestoDates(resto: Resto) {
  resto.visitAt = new Date(resto.visitAt);
}


export function isItemInResto(item: ItemObject, resto: Resto) {
  if (item.itemType === "Duty") {
    if (isDutyInResto(item.item, resto)) { return true; }
  }
  if (item.itemType === "Info") {
    if (isInfoInResto(item.item, resto)) { return true; }
  }
  if (item.itemType === "Link") {
    if (isLinkInResto(item.item, resto)) { return true; }
  }
  if (item.itemType === "Image") {
    if (isImageInResto(item.item, resto)) { return true; }
  }
  if (item.itemType === "Notice") {
    if (isNoticeInResto(item.item, resto)) { return true; }
  }
}

export function isRestoInContainer(resto: Resto, inContainer: Container) {

  // test all restos directly in container
  for (const xResto of inContainer.restos) {
    if (resto.id === xResto.id) {
      return true;
    }
  }
  // test all child-containers of container
  for (const xContainer of inContainer.containers) {
    if (isRestoInContainer(resto, xContainer)) { return true; }
  }
  // test all child-places of container
  for (const xPlace of inContainer.places) {
    if (isRestoInPlace(resto, xPlace)) { return true; }
  }
  return false;
}

export function isRestoInPlace(resto: Resto, inPlace: Place) {

  // test all restos directly in place
  for (const xResto of inPlace.restos) {
    if (resto.id === xResto.id) {
      return true;
    }
  }
  // test all child-containers of place
  for (const xContainer of inPlace.containers) {
    if (isRestoInContainer(resto, xContainer)) { return true; }
  }
  // test all child-places of place
  for (const xPlace of inPlace.places) {
    if (isRestoInPlace(resto, xPlace)) { return true; }
  }
  return false;
}

export function isRestoPrivate(resto: Resto, inTrip: Trip) {
  for (const xContainer of inTrip.containers) {
    if (!xContainer.userId) { continue; }
    if (isRestoInContainer(resto, xContainer)) { return true; }
  }
  return false;
}

export function getRestoInTrip(restoId: number, inTrip: Trip) {
  // console.log("util-testos:getRestoInTrip-restoId", restoId);

  // search in restos
  // for (const resto of inTrip.restos) {
  //   if (resto.id === restoId) { return resto; }
  // }
  // search in sub-containers
  for (const xContainer of inTrip.containers) {
    const resto = getRestoInContainer(restoId, xContainer);
    if (resto) { return resto; }
  }
  // search in sub-places
  for (const xPlace of inTrip.places) {
    const resto = getRestoInPlace(restoId, xPlace);
    if (resto) { return resto; }
  }
}
export function getRestoInContainer(restoId: number, inContainer: Container) {
  // console.log("util-testos:getRestoInTrip-restoId", restoId);

  // search in restos
  for (const resto of inContainer.restos) {
    if (resto.id === restoId) { return resto; }
  }
  // search in sub-containers
  for (const xContainer of inContainer.containers) {
    const resto = getRestoInContainer(restoId, xContainer);
    if (resto) { return resto; }
  }
  // search in sub-places
  for (const xPlace of inContainer.places) {
    const resto = getRestoInPlace(restoId, xPlace);
    if (resto) { return resto; }
  }
}
export function getRestoInPlace(restoId: number, inPlace: Place) {
  // console.log("util-testos:getRestoInTrip-restoId", restoId);

  // search in restos
  for (const resto of inPlace.restos) {
    if (resto.id === restoId) { return resto; }
  }
  // search in containers
  for (const xContainer of inPlace.containers) {
    const resto = getRestoInContainer(restoId, xContainer);
    if (resto) { return resto; }
  }
  // search in sub-places
  for (const xPlace of inPlace.places) {
    const resto = getRestoInPlace(restoId, xPlace);
    if (resto) { return resto; }
  }
}

export function getMinLocalRestoIdForTrip(inTrip: Trip) {
  let id = 0;
  // test restos of trip
  for (const xResto of inTrip.restos) {
    if (xResto.id < id) { id = xResto.id; }
  }
  // search in trip-containers
  for (const xContainer of inTrip.containers) {
    const restoId = getMinLocalRestoIdInContainer(xContainer);
    if (restoId < id) { id = restoId; }
  }
  // search in trip-places
  for (const xPlace of inTrip.places) {
    const restoId = getMinLocalRestoIdInPlace(xPlace);
    if (restoId < id) { id = restoId; }
  }
  return id;
}
export function getMinLocalRestoIdInContainer(inContainer: Container) {
  // console.log("EventFind:getPlaceInContainer-placeId", placeId);
  let id = 0;
  // test restos of container
  for (const xResto of inContainer.restos) {
    if (xResto.id < id) { id = xResto.id; }
  }
  // search in container-containers
  for (const xContainer of inContainer.containers) {
    const restoId = getMinLocalRestoIdInContainer(xContainer);
    if (restoId < id) { id = restoId; }
  }
  // search in container-places
  for (const xPlace of inContainer.places) {
    const restoId = getMinLocalRestoIdInPlace(xPlace);
    if (restoId < id) { id = restoId; }
  }
  return id;
}
export function getMinLocalRestoIdInPlace(inPlace: Place) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test events of place
  for (const xResto of inPlace.restos) {
    if (xResto.id < id) { id = xResto.id; }
  }
  // search in place-containers
  for (const xContainer of inPlace.containers) {
    const restoId = getMinLocalRestoIdInContainer(xContainer);
    if (restoId < id) { id = restoId; }
  }
  // search in place-places
  for (const xPlace of inPlace.places) {
    const restoId = getMinLocalRestoIdInPlace(xPlace);
    if (restoId < id) { id = restoId; }
  }
  return id;
}

export function getRestoParentContainerForTrip(resto: Resto, trip: Trip, onlyForRefRegion: boolean) {
  // console.log("utilsResto:getEventParentContainerForTrip-event", event);

  // parent is trip
  if (resto.tripId) { return null; }

  // parent is container
  if (resto.containerId) {
    const parentContainer = getContainerInTrip(resto.containerId, trip);
    // console.log("UtilsResto:getEventParentContainerForTrip-parentContainer", parentContainer);
    if (!onlyForRefRegion) { return parentContainer; }
    if (parentContainer.refRegionId || parentContainer.refContainerId) { return parentContainer; }
    const resContainer = getContainerParentContainerForTrip(parentContainer, trip, onlyForRefRegion);
    if (resContainer) { return resContainer; }
  }

  // parent is place
  if (resto.placeId) {
    const parentPlace = getPlaceInTrip(resto.placeId, trip);
    // console.log("UtilsResto:getEventParentContainerForTrip-parentPlace", parentPlace);
    const resContainer = getPlaceParentContainerForTrip(parentPlace, trip, onlyForRefRegion);
    if (resContainer) { return resContainer; }
  }

}

export function getRestoParentPlaceForTrip(resto: Resto, trip: Trip, onlyForRefPlace: boolean) {
  // console.log("UtilsResto:getEventParentPlaceForTrip-event", event);

  // parent is container
  if (resto.containerId) {
    const parentContainer = getContainerInTrip(resto.containerId, trip);
    console.log("UtilsResto:getEventParentPlaceForTrip-parentContainer", parentContainer);
    const resPlace = getContainerParentPlaceForTrip(parentContainer, trip, onlyForRefPlace);
    if (resPlace) { return resPlace; }
  }

  // parent is place
  if (resto.placeId) {
    const parentPlace = getPlaceInTrip(resto.placeId, trip);
    console.log("EventFind:getEventParentPlaceForTrip-parentPlace", parentPlace);
    if (!onlyForRefPlace) { return parentPlace; }
    if (parentPlace.refPlaceId) { return parentPlace; }
    const resPlace = getPlaceParentPlaceForTrip(parentPlace, trip, onlyForRefPlace);
    if (resPlace) { return resPlace; }
  }
}

